/* eslint-disable prettier/prettier */
import { gql } from "@apollo/client";

export const GET_POSTS = gql`
  query GetPosts($older: DateTime, $newer: DateTime, $range: Int, $hideViewed: Boolean) {
    getPosts(older: $older, newer: $newer, range: $range, hideViewed: $hideViewed) {
      data {
        id
        body
        url
        author
        authorProfileUrl
        location
        timestamp
        source
        status
        sourceType
        sourceId
        sourceUrl
        tags
        threadTitle
        review
      }
      count
    }
  }
`;

export const GET_CURRENT_USER = gql`
  {
    me {
      id
      firstName
      lastName
      fullName
      phone
      company {
        id
        timezone
        isOptinConsentMethod
        isGlobalCrm
      }
      userAccounts {
        company {
          id
          name
          timezone
          isOptinConsentMethod
          isGlobalCrm
        }
      }
    }
  }
`;

export const GET_CURRENT_USER_COMPANY_USERS = gql`
  {
    me {
      id
      firstName
      lastName
      fullName
      email
      phone
      companyRole {
        id
        name
        parentRoleId
      }
      company {
        id
        isGlobalCrm
        users {
          id
          firstName
          lastName
          fullName
          email
          isDisabled
          phone
        }
      }
      locationId
    }
  }
`;

export const GET_CURRENT_USER_COMPANY_TEAMS = gql`
  {
    getTeams {
      id
      name
      leaderId
      status
      leader {
        id
        fullName
      }
      members {
        memberId
        member {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getUsers(
    $page: Int
    $pageSize: Int
    $search: String
    $companyId: Int!
    $isGlobalLeadOwner: Boolean
    $isLocationFilter: Boolean
    $locationIds: [Int]
    $allUser: Boolean
    $companyRoles: [Int]
    $isActive: Boolean
    $status: [String]
    $hasPhoneNumber: Boolean
  ) {
    getUsers(
      page: $page
      pageSize: $pageSize
      search: $search
      companyId: $companyId
      isGlobalLeadOwner: $isGlobalLeadOwner
      isLocationFilter: $isLocationFilter
      locationIds: $locationIds
      allUser: $allUser
      companyRoles: $companyRoles
      isActive: $isActive
      status: $status
      hasPhoneNumber: $hasPhoneNumber
    ) {
      data {
        companyId
        id
        firstName
        lastName
        fullName
        email
        isDisabled
        dateCreated
        locationId
        userRoles {
          id
          companyId
          roleId
          role {
            id
            name
          }
          company {
            id
            name
            timezone
            locationLink
            isOptinConsentMethod
            isGlobalCrm
          }
        }
        status
        phone
        companyRoleId
        companyRole {
          id
          name
          locations {
            locationId
          }
        }
        userAccounts {
          id
          userId
          companyId
          isDisabled
          company {
            id
            isGlobalCrm
          }
        }
        role {
          id
          name
          canViewAutoAnalytics
          canViewAdExport
        }
        location {
          id
          title
        }
        locations {
          id
          userId
          locationId
          isPrimary
        }
      }
      count
    }
  }
`;
export const GET_FILTER_USERS = gql`
  query getUsers(
    $page: Int
    $pageSize: Int
    $search: String
    $companyId: Int!
    $isGlobalLeadOwner: Boolean
    $isLocationFilter: Boolean
    $locationIds: [Int]
    $allUser: Boolean
    $companyRoles: [Int]
    $isActive: Boolean
    $status: [String]
    $isAllLocationUsers: Boolean
  ) {
    getUsers(
      page: $page
      pageSize: $pageSize
      search: $search
      companyId: $companyId
      isGlobalLeadOwner: $isGlobalLeadOwner
      isLocationFilter: $isLocationFilter
      locationIds: $locationIds
      allUser: $allUser
      companyRoles: $companyRoles
      isActive: $isActive
      status: $status
      isAllLocationUsers: $isAllLocationUsers
    ) {
      data {
        id
        firstName
        lastName
        fullName
        companyRole {
          id
          name
        }
      }
      count
    }
  }
`;
export const GET_ALL_TEAMS = gql`
  query getTeams($page: Int, $pageSize: Int, $search: String, $companyId: Int!) {
    getTeams(page: $page, pageSize: $pageSize, search: $search, companyId: $companyId) {
      data {
        id
        name
        leaderId
        status
        leader {
          id
          fullName
        }
        members {
          memberId
          member {
            id
            fullName
          }
        }
      }
      count
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query roles($companyId: Int!) {
    roles(companyId: $companyId) {
      id
      name
      canCreateUsers
      canCreateTeams
      canViewProspects
      isCompanyAdmin
      canViewAutoAnalytics
      canViewAdExport
      canViewClm
      canViewGle
      canViewEngagements
    }
  }
`;

export const GET_USER_FILTERS = gql`
  {
    me {
      id
      filters {
        id
        type
        typeName
        value
        companyFilterId
        setType
        filterType {
          isDynamic
        }
      }
      filterSets {
        userId
        name
        setType
        id
      }
      responseTemplates {
        id
        message
        isInitialResponse
      }
    }
  }
`;

export const GET_DISPLAY_FILTERS = gql`
  {
    getUserDisplayFilters {
      id
      type
      typeName
      value
      selectionOption {
        id
        value
        query
      }
    }
  }
`;

export const ALL_EVENTS = gql`
  {
    allEvents {
      duration
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const GET_SAVED_POSTS = gql`
  query getSavedPosts($setType: String) {
    getSavedPosts(setType: $setType) {
      id
      userId
      companyId
      aingineDataId
      status
      post {
        id
        body
        url
        author
        authorProfileUrl
        location
        timestamp
        source
        status
        sourceId
        sourceUrl
        sourceType
        tags
        review
      }
    }
  }
`;

export const GET_DEALS = gql`
  query GetDeals($status: String, $orderBy: String, $orderDirection: String, $postType: String) {
    getDeals(
      status: $status
      postType: $postType
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      userId
      salesPerson {
        fullName
        id
      }
      companyId
      aingineDataId
      screenName
      firstName
      lastName
      email
      phone
      location
      url
      profileUrl
      source
      strength
      status
      dateCreated
      followupDate
      subscribed
      tags
      allowNotifications
      conversations {
        id
        type
        message
        postTime
        aingineUserId
        aingineDataId
      }
      postType
    }
  }
`;

export const GET_COMPANIES = gql`
  {
    getCompanies {
      id
      name
      address
      city
      state
      postalCode
      country
      phone
      userCount
      dateCreated
      isDisabled
      timezone
      locationLink
      isOptinConsentMethod
      website
      isGlobalCrm
      isOpenCrmWarningPopup
      profilePic
      crmIntegration {
        integrationType
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query GetCompany($id: Int!) {
    company(id: $id) {
      id
      name
      phone
      address
      city
      state
      postalCode
      country
      website
      industry
      addressDetail
      userCount
      dateCreated
      isDisabled
      timezone
      locationLink
      isOptinConsentMethod
      isGlobalCrm
      isOpenCrmWarningPopup
    }
  }
`;

export const GET_MY_COMPANY = gql`
  query GetMyCompany {
    me {
      id

      company {
        id
        name
        phone
        address
        city
        state
        postalCode
        country
        website
        industry
        addressDetail
        userCount
        dateCreated
        isDisabled
        automaticEngagement
        timezone
        locationLink
        isOptinConsentMethod
        isGlobalCrm
        isOpenCrmWarningPopup
        profilePic
      }
    }
  }
`;

export const GET_COMPANY_USERS = gql`
  query GetCompanyUsers($id: Int!, $locationIds: [Int]) {
    company(id: $id, locationIds: $locationIds) {
      id
      name
      users {
        id
        fullName
        phone
        email
        locationId
        companyRoleId
        companyRole {
          id
          name
        }
        locations {
          location {
            id
            title
          }
        }
      }
      userAccounts {
        isDisabled
        user {
          id
          fullName
          userRoles {
            id
            companyId
            roleId
            role {
              id
              name
            }
            company {
              id
              name
              timezone
              locationLink
              isOptinConsentMethod
              isGlobalCrm
            }
          }
          location {
            id
            title
            locationType
          }
          firstName
          lastName
          email
          phone
          isDisabled
          dateCreated
          status
          locationId
          companyRoleId
          responseTemplates {
            id
            message
            isInitialResponse
          }
          role {
            id
            name
          }
          locations {
            location {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_FILTER_TYPES = gql`
  query GetFilterTypes {
    getFilterTypes {
      id
      type
      name
      filterField
      optionsCount
      selectionOptions {
        id
        value
        query
      }
    }
  }
`;

export const FILTER_TYPE = gql`
  query FilterType($id: Int!) {
    filterType(id: $id) {
      selectionOptions {
        id
        value
        query
      }
    }
  }
`;

export const GET_COMPANY_FILTERS = gql`
  query GetCompanyFilters($id: Int!) {
    company(id: $id) {
      filters {
        id
        value
        type
        typeName
        filterField
        userCanChange
        selectionOption {
          id
        }
      }
    }
  }
`;

export const SEARCH_COMPANIES = gql`
  query SearchCompanyByName($name: String!) {
    searchCompanyByName(name: $name) {
      id
      name
      address
      city
      state
      postalCode
      country
      phone
      userCount
      dateCreated
      isDisabled
      timezone
      locationLink
      isOpenCrmWarningPopup
    }
  }
`;

export const USER_FILTERS_BY_ID = gql`
  query GetUserFilters($id: Int!) {
    user(id: $id) {
      filters {
        id
        type
        typeName
        value
        setType
      }
    }
  }
`;

export const ROLES = gql`
  query Roles($companyId: Int) {
    roles(companyId: $companyId) {
      id
      name
      canCreateUsers
      canCreateTeams
      canViewProspects
      isCompanyAdmin
      canViewAutoAnalytics
      canViewAdExport
      canViewClm
      canViewGle
      canViewEngagements
    }
  }
`;

// TODO: add id ?
export const SCREEN_NAME_CHECK = gql`
  query ScreenNameCheck($sourceId: Int!) {
    screenNameCheck(sourceId: $sourceId) {
      hasScreenName
      screenName {
        id
        screenName
      }
    }
  }
`;

export const GET_CRM_INTEGRATION = gql`
  query GetCrmIntegration($companyId: Int!) {
    crmIntegration: crmIntegrationByCompany(companyId: $companyId) {
      id
      companyId
      integrationType
      adfEmail
      crmDealerId
      vsLeadSourceId
      sfApiUrl
      sfApiKey
      sfApiUser
      sfCertificateKey
      elSubscriptionId
      crmFranchiseId
      locationId
      active
      location {
        id
        title
        locationType
      }
    }
  }
`;

export const GET_USER_TEAM = gql`
  query GetUserTeam {
    me {
      id
      teamsLeader {
        members {
          member {
            firstName
            lastName
            fullName
            id
          }
        }
      }
      isCompanyAdmin
      isDisabled
      company {
        id
        isGlobalCrm
        userAccounts {
          isDisabled
          user {
            firstName
            lastName
            fullName
            id
            locationId
          }
        }
      }
    }
  }
`;

export const SEARCH_DEALS = gql`
  query SearvhDeals($searchTerm: String!) {
    searchDeals(searchTerm: $searchTerm) {
      id
      userId
      salesPerson {
        fullName
        id
      }
      companyId
      aingineDataId
      screenName
      firstName
      lastName
      email
      phone
      location
      url
      profileUrl
      source
      strength
      status
      dateCreated
      followupDate
      tags
      allowNotifications
      conversations {
        id
        type
        message
        postTime
        aingineUserId
        aingineDataId
      }
    }
  }
`;

// TODO: add id ?
export const GET_ANALYTICS_KPIS = gql`
  query GetAnalyticsKpis($range: Int!, $teamId: Int!, $allMembers: Boolean) {
    getAnalyticsKpis(range: $range, allMembers: $allMembers) {
      date
      userId
      status
      count
      source
      userName
    }
    team(id: $teamId) {
      members {
        member {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_USER_SCREENNAMES = gql`
  query GetUserScreenNames {
    me {
      id
      screenNames {
        id
        source
        sourceId
        screenName
        sourceUrl
      }
    }
  }
`;

export const GET_SOURCES = gql`
  query GetSources {
    getSources {
      source
      sourceId
      sourceUrl
    }
  }
`;

export const GET_USER_RESPONSE_TEMPLATES = gql`
  query GetUserResponseTemplates {
    me {
      id
      responseTemplates {
        id
        message
        isInitialResponse
      }
    }
  }
`;

// TODO: add id ?
export const GET_APP_USAGE_ALL = gql`
  query getAppUsage {
    getAnalyticsAppUsage {
      date
      time
      userName
      converted
      provided
      engaged
      crm
    }
  }
`;

// TODO: add id ?
export const GET_APP_USAGE = gql`
  query getAppUsage {
    getAnalyticsAppUsage {
      date
      time
      userName
    }
  }
`;

// TODO: add id ?
export const GET_ANALYTICS_POSTS = gql`
  query getAnalyticsPosts($range: Int) {
    getAnalyticsPosts(range: $range) {
      timestamp
      count
    }
  }
`;

// TODO: add id ?
export const GET_ANALYTICS_CATEGORIES = gql`
  query getAnalyticsCategories($range: Int) {
    getAnalyticsCategories(range: $range) {
      name
      total
      children {
        name
        count
      }
    }
  }
`;
export const GET_PERMISSION_LEAD_IDS = gql`
  query getPermissionLeadIds {
    getPermissionLeadIds
  }
`;
export const GET_NOTIFICATIONS = gql`
  query getNotifications(
    $older: DateTime
    $notificationTypes: [String]
    $leadIds: [Int]
    $orderBy: String
    $orderDirection: String
  ) {
    getNotifications(
      older: $older
      notificationTypes: $notificationTypes
      leadIds: $leadIds
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      leadId
      notifications {
        id
        date
        notificationType
        text
        read
        clickableLink
        locationId
        leadId
        lead {
          locationId
          fullName
          phoneNumbers {
            id
            phone
            phoneType
          }
        }
      }
    }
  }
`;

export const GET_UNREAD_NOTIFICATIONS_COUNT = gql`
  {
    getUnreadNotificationsCount
  }
`;

export const GET_EXPORT_CONFIGS = gql`
  query getExportConfigs {
    getExportConfigs {
      id
      userId
      user {
        firstName
        lastName
      }
      name
      email
      minimumCount
      frequency
      count
      lastExported
      filters {
        typeName
        value
        type
      }
      timezone
    }
  }
`;

export const GET_EXPORT_CONFIG = gql`
  query exportConfig($id: Int!) {
    exportConfig(id: $id) {
      id
      userId
      name
      email
      minimumCount
      frequency
      count
      lastExported
      emailTime
      filters {
        id
        type
        typeName
        value
        companyFilterId
        setType
      }
      timezone
    }
  }
`;

export const GET_EXPORTS = gql`
  query getExports {
    getExports {
      id
      name
      createdAt
      count
      exportConfig {
        userId
        user {
          firstName
          lastName
        }
        name
        adHoc
        email
        minimumCount
        count
        lastExported
        filters {
          type
          typeName
          value
        }
      }
    }
  }
`;

export const GET_ALL_PERSONS = gql`
  query allPersons($sourceId: Int) {
    allPersons(sourceId: $sourceId) {
      id
      fullName
      emails {
        address
      }
      phoneNumbers {
        number
      }
      addresses {
        line1
      }
    }
  }
`;

export const GET_PAGINATED_PERSONS = gql`
  query paginatedPersons(
    $sourceId: Int
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $orderDirection: String
  ) {
    paginatedPersons(
      sourceId: $sourceId
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        id
        fullName
        emails {
          address
        }
        phoneNumbers {
          number
        }
        addresses {
          line1
        }
      }
      count
    }
  }
`;

export const GET_PERSON = gql`
  query person($id: Int!) {
    person(id: $id) {
      id
      firstName
      lastName
      dob
      addresses {
        id
        type
        current
        line1
        line2
        locality
        region
        country
        latitude
        longitude
        location
        postalCode
      }
      emails {
        id
        type
        address
      }
      phoneNumbers {
        id
        type
        number
      }
      languages {
        id
        language
      }
      education {
        id
        schoolName
        level
        degree
        startDate
        endDate
        location
        description
      }
      experience {
        id
        title
        company
        startDate
        endDate
        location
        description
      }
      certificates {
        id
        type
        title
        issuingAuthority
        date
      }
      volunteering {
        id
        role
        location
        dateStart
        dateEnd
        cause
        description
      }
      skills {
        id
        name
        strength
      }
      accomplishments {
        id
        type
        title
        url
        description
      }
      publications {
        id
        name
        publisher
        date
        url
      }
      awards {
        id
        name
        location
        date
        description
      }
      interests {
        id
        type
        name
        url
      }
      possessions {
        id
        type
        name
      }
      accounts {
        account {
          id
          profileUrl
          username
          location
          about
          source {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_LIFE_EVENTS_POSTS = gql`
  query GetLifeEventsPosts(
    $older: DateTime
    $newer: DateTime
    $range: Int
    $globalEvents: Boolean
  ) {
    getLifeEventsPosts(older: $older, newer: $newer, range: $range, globalEvents: $globalEvents) {
      data {
        id
        body
        url
        author
        authorProfileUrl
        location
        timestamp
        source
        status
        sourceType
        sourceId
        sourceUrl
        tags
        threadTitle
        review
        personId
        personFullName
      }
      count
    }
  }
`;

// TODO: add id ?
export const GET_PERSON_DESCRIPTOR = gql`
  {
    personDescriptor {
      modelName
      fields {
        name
        nullable
        type
        options
      }
    }
  }
`;

export const ALL_UNIQUE_SOURCES = gql`
  query AllUniqueSources($exclude: [String]) {
    allUniqueSources(exclude: $exclude) {
      sourceType
      id
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      email
      id
      fullName
      location {
        id
        title
      }
      userAccounts {
        id
        userId
        companyId
      }
      userRoles {
        roleId
        userId
        id
      }
    }
  }
`;

export const GET_EVAL_TERMS = gql`
  {
    getEvalTerms {
      id
      text
      include
      exclude
      intent
    }
  }
`;

export const GET_LEAD_NAMES = gql`
  query getLeads(
    $source: [String]
    $sourceOriginal: [String]
    $combinedSource: [String]
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $orderDirection: String
    $voi: [VehicleOfInterestInputObject]
    $campaignIds: [Int]
    $status: [String]
    $leadStatusTypes: [Int]
    $crmLeadStatus: [Int]
    $isSameGroup: Boolean
    $dsSources: [String]
  ) {
    getLeads(
      source: $source
      sourceOriginal: $sourceOriginal
      combinedSource: $combinedSource
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
      voi: $voi
      campaignIds: $campaignIds
      status: $status
      leadStatusTypes: $leadStatusTypes
      crmLeadStatus: $crmLeadStatus
      isSameGroup: $isSameGroup
      dsSources: $dsSources
    ) {
      data {
        id
        fullName
        phoneNumbers {
          id
          phone
        }
        crmLeadObj {
          crmStatus
          parentCrmStatus
          activeLeadOpportunity {
            id
          }
        }
      }
      count
    }
  }
`;
export const GET_LEAD_NAME_BY_ID = gql`
  query lead($leadId: Int!) {
    lead(leadId: $leadId) {
      id
      fullName
    }
  }
`;
export const GET_LEAD = gql`
  query lead($leadId: Int!) {
    lead(leadId: $leadId) {
      id
      fullName
      firstName
      lastName
      dateOfBirth
      leadSourceType
      leadSourceOriginalId
      companyId
      leadFileId
      crmIntegrationId
      status
      emailConsent
      emailConsentDate
      textConsent
      textConsentDate
      textConsentStatus
      otherSource
      disableConversation
      leadStatusTypeId
      locationId
      leadStatusType {
        id
        type
        status
      }
      emails {
        id
        leadId
        email
        emailType
      }
      phoneNumbers {
        id
        leadId
        phone
        phoneType
        lookupType
      }
      addresses {
        id
        leadId
        locationText
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      vehicleOfInterest {
        id
        leadId
        year
        make
        model
        trim
        description
        budget
        customerInterest
        inputType
        isCurrent
        isPrimary
        stockNumber
        vin
      }
      leadVehicleInventory {
        id
        make
        model
        year
        trim
        stockNumber
        vin
      }
      leadSource {
        name
        id
        parentSource {
          id
          name
          parentSource {
            id
            name
          }
        }
      }

      leadUsers {
        userType {
          id
          useCase
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
      location {
        id
        title
        timezone
      }
      crmLeadObj {
        crmIntegrationType
        crmStatus
        parentCrmStatus
        activeLeadOpportunity {
          id
          opportunityStatus {
            id
            name
            parentStatus {
              id
              name
            }
          }
          opportunitySource {
            id
            name
            parentSource {
              id
              name
              parentSource {
                id
                name
              }
            }
          }
          vehiclesOfInterest {
            id
            leadId
            year
            make
            model
            trim
            description
            budget
            customerInterest
            inputType
            isCurrent
            isPrimary
            stockNumber
            vin
          }
        }
        customerType {
          id
          type
        }
        customerSubType {
          id
          type
        }
        dsExtractedLead {
          id
          dsLeadId
          dsSource
        }
        activeDsOpportunityEvent {
          id
          dsEventId
          dsMarketingChannelName
        }
        sfExtractedLead {
          id
          sfLeadId
        }
        mfExtractedLead {
          id
          mfProspectId
          mfCustomerId
        }
        activeSfOpportunityEvent {
          id
          sfOpportunityId
        }
      }
    }
  }
`;

export const GET_LEAD_DESCRIPTOR = gql`
  {
    leadDescriptor {
      modelName
      fields {
        name
        nullable
        type
        options
      }
    }
  }
`;

export const GET_LEAD_SOURCES = gql`
  {
    leadSources {
      id
      name
      isAddedFromCrm
      parentSource {
        id
        name
        parentSource {
          id
          name
        }
      }
    }
  }
`;

export const GET_EXTENSION_LEAD_SOURCES = gql`
  {
    leadSources {
      id
      name
      isAddedFromCrm
    }
  }
`;

export const GET_ALL_CAMPAIGNS = gql`
  {
    campaigns {
      id
      name
      isDisabled
    }
  }
`;
export const GET_CAMPAIGN_PAGINATED_LIST = gql`
  query getCampaigns($page: Int, $pageSize: Int, $status: String) {
    getCampaigns(page: $page, pageSize: $pageSize, activeInd: $status) {
      data {
        id
        name
        startDate
        endDate
        method
        textMessage
        activeInd
        # dateCreated
        isDisabled
        isPrioritize
        source
        status
        # campaignSelections {
        #   id
        #   type
        #   value
        #   campaignId
        # }
        # campaignTemplates {
        #   id
        #   scheduleId
        #   sourceId
        #   templateText
        #   activeInd
        #   isAfterHour
        #   afterHourTemplateText
        #   campaignSchedules {
        #     id
        #     type
        #     numericValue
        #     temporalValue
        #     sortOrder
        #     title
        #   }
        # }
        # campaignSchedules {
        #   id
        #   type
        #   numericValue
        #   temporalValue
        #   title
        #   sortOrder
        # }
        user {
          id
          fullName
          firstName
          lastName
          locationId
        }
        leadMessageCount {
          campaignId
          totalSent
          totalDelivered
          totalResponded
          totalUncontacted
          responseRate
          optOutRate
          totalEngaged
          avgAttemptsBeforeResponse
          soldOpportunityCount
          scheduledAppointmentCount
          rescheduledAppointmentCount
          showedAppointmentCount
          cancelledAppointmentCount
          noShowedAppointmentCount
          ottoAppointmentSchCount
          ottoAppointmentConfCount
          totalLeads
        }
      }
      count
    }
  }
`;
export const GET_CAMPAIGN_MESSAGES = gql`
  query campaignSchedules($campaignId: Int!) {
    campaignSchedules(campaignId: $campaignId) {
      id
      type
      numericValue
      temporalValue
      title
      sortOrder
      campaignTemplates {
        id
        scheduleId
        sourceId
        isAfterHour
        afterHourTemplateText
        templateText
      }
    }
  }
`;
export const GET_ALL_CAMPAIGN_SUMMARY = gql`
  query getCampaigns(
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $orderDirection: String
    $activeInd: String
  ) {
    getCampaigns(
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
      activeInd: $activeInd
    ) {
      data {
        leadMessageCount {
          campaignId
          totalSent
          totalDelivered
          totalResponded
          totalUncontacted
          responseRate
          optOutRate
          totalEngaged
          avgAttemptsBeforeResponse
          soldOpportunityCount
          scheduledAppointmentCount
          rescheduledAppointmentCount
          showedAppointmentCount
          cancelledAppointmentCount
          noShowedAppointmentCount
          ottoAppointmentSchCount
          ottoAppointmentConfCount
          totalLeads
        }
      }
    }
  }
`;
export const GET_CAMPAIGN_DETAILS = gql`
  query campaign($campaignId: Int!, $startDate: DateTime, $endDate: DateTime) {
    campaign(campaignId: $campaignId, startDate: $startDate, endDate: $endDate) {
      data {
        leadMessageCount {
          campaignId
          totalSent
          totalDelivered
          totalResponded
          totalUncontacted
          responseRate
          optOutRate
          totalEngaged
          avgAttemptsBeforeResponse
          soldOpportunityCount
          scheduledAppointmentCount
          rescheduledAppointmentCount
          showedAppointmentCount
          cancelledAppointmentCount
          noShowedAppointmentCount
          ottoAppointmentSchCount
          ottoAppointmentConfCount
          totalLeads
        }
        # campaignSelections {
        #   id
        #   type
        #   value
        # }
        # campaignSchedules {
        #   id
        #   type
        #   numericValue
        #   temporalValue
        #   title
        #   sortOrder
        #   campaignTemplates {
        #     id
        #     scheduleId
        #     sourceId
        #     templateText
        #     activeInd
        #     isAfterHour
        #     afterHourTemplateText
        #   }
        # }
        # campaignTemplates {
        #   id
        #   scheduleId
        #   sourceId
        #   templateText
        #   activeInd
        #   isAfterHour
        #   afterHourTemplateText
        #   campaignSchedules {
        #     id
        #     type
        #     numericValue
        #     temporalValue
        #     title
        #     sortOrder
        #   }
        # }
      }
    }
  }
`;
export const GET_ALL_CAMPAIGN_TEMPLATES = gql`
  query getCampaignTemplates(
    $page: Int
    $pageSize: Int
    $search: String
    $campaignId: Int!
    $sourceId: Int
    $scheduleId: Int
  ) {
    getCampaignTemplates(
      page: $page
      pageSize: $pageSize
      search: $search
      campaignId: $campaignId
      sourceId: $sourceId
      scheduleId: $scheduleId
    ) {
      data {
        campaignId
        id
        sourceId
        scheduleId
        templateText
        activeInd
        isAfterHour
        afterHourTemplateText
        campaignSchedules {
          id
          type
          numericValue
          temporalValue
          title
          sortOrder
        }
        leadSource {
          id
          name
        }
      }
      count
    }
  }
`;
export const GET_CAMPAIGN_SCHEDULES = gql`
  query campaignSchedules($campaignId: Int!) {
    campaignSchedules(campaignId: $campaignId) {
      id
      type
      numericValue
      temporalValue
      title
      sortOrder
      campaignTemplates {
        id
        sourceId
        templateText
        afterHourTemplateText
        isAfterHour
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query messages(
    $leadId: Int!
    $page: Int
    $pageSize: Int
    $campaignIds: [Int]
    $leadOpportunityId: Int
  ) {
    messages(
      leadId: $leadId
      page: $page
      pageSize: $pageSize
      campaignIds: $campaignIds
      leadOpportunityId: $leadOpportunityId
    ) {
      data {
        id
        systemUserId
        leadId
        userId
        user {
          firstName
          lastName
          fullName
          profilePic
        }
        # channelId
        # campaignId
        translatedContent
        campaign {
          name
        }
        # campaignTemplateId
        direction
        dateSent
        dateReceived
        content
        translatedContent
        contentType
        messageType
        errorCode
        errorMessage
        # statusCode
        messageStatus
        mmsObjects {
          id
          mediaUrl
          mediaType
          thumbnail
          size
        }
        messageLog {
          id
          messageId
          toPhone
          fromPhone
        }
        trackingDetails {
          id
          actionType
          actionValue
        }
      }
      count
      lastId
      isRefresh
    }
  }
`;

export const GET_CHANNEL = gql`
  query channel($id: Int!) {
    channel(id: $id) {
      id
      name
      channelType
    }
  }
`;

export const GET_MESSAGE_LOG = gql`
  query messageLog($id: Int!) {
    messageLog(id: $id) {
      id
      messageId
      fromPhone
      toPhone
    }
  }
`;

export const GET_COMPANY_USER_BY_ID = gql`
  query companyUserById($id: Int!) {
    companyUserById(id: $id) {
      id
      fullName
      firstName
    }
  }
`;

export const GET_SYSTEM_USER = gql`
  {
    systemUser {
      phone
    }
  }
`;

export const GET_ALL_VEHICLE_OF_INTEREST = gql`
  query getAllVoi(
    $source: [String]
    $sourceOriginal: [String]
    $combinedSource: [String]
    $page: Int
    $pageSize: Int
    $search: String
  ) {
    getAllVoi(
      source: $source
      sourceOriginal: $sourceOriginal
      combinedSource: $combinedSource
      page: $page
      pageSize: $pageSize
      search: $search
    ) {
      make
      model
      year
      text
    }
  }
`;

export const GET_SOURCES_FROM_LEADS = gql`
  {
    getAllSourceOriginal
  }
`;

export const GET_LEADS_COUNT_BY_CAMPAIGN = gql`
  query getLeads(
    $source: [String]
    $sourceOriginal: [String]
    $combinedSource: [String]
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $orderDirection: String
    $voi: [VehicleOfInterestInputObject]
    $campaignIds: [Int]
    $leadStatusTypes: [Int]
  ) {
    getLeads(
      source: $source
      sourceOriginal: $sourceOriginal
      combinedSource: $combinedSource
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
      voi: $voi
      campaignIds: $campaignIds
      leadStatusTypes: $leadStatusTypes
    ) {
      count
    }
  }
`;

export const GET_CAMPAIGN_LEAD_SUMMARY = gql`
  query getCampaignLeadSummary(
    $campaignId: Int
    $leadId: Int
    $attempt: [String]
    $status: [String]
    $search: String
    $page: Int
    $pageSize: Int
    $orderBy: String
    $orderDirection: String
  ) {
    getCampaignLeadSummary(
      campaignId: $campaignId
      leadId: $leadId
      attempt: $attempt
      status: $status
      search: $search
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        id
        campaignId
        leadId
        lastMessageSentDate
        lastMessageReceivedDate
        numAttemptsBeforeResponse
        status
        lead {
          id
          fullName
          firstName
          lastName
          companyId
          leadSourceType
          leadSourceOriginalId
          dateOfBirth
          leadCreatedDate
          emailConsent
          textConsent
          textConsentDate
          textConsentStatus
          status
          otherSource
          unreadCount
          leadStatusTypeId
          leadStatusType {
            id
            type
            status
          }
          emails {
            id
            email
            emailType
          }
          phoneNumbers {
            id
            phone
            phoneType
          }
          addresses {
            id
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
          }
          vehicleOfInterest {
            id
            leadId
            year
            make
            model
            trim
            description
            budget
            customerInterest
            inputType
            isCurrent
            isPrimary
            stockNumber
            vin
          }
          messages {
            id
            dateSent
            dateReceived
            messageStatus
            messageType
          }
          leadSource {
            id
            name
          }

          activeAppointment {
            id
            startDatetime
            appointmentStatus
            isConfirmed
          }
          consentExpireDay
          conversationStatus {
            id
            userId
            disableConversation
            createdOn
          }
          location {
            id
            title
            locationType
            timezone
          }
          leadUsers {
            userType {
              id
              useCase
              name
            }
            user {
              id
              firstName
              lastName
              companyRole {
                id
                name
              }
            }
          }
          campaignObj {
            campaigns {
              id
              name
            }
            activelyCampaign
          }
        }
      }
      count
    }
  }
`;

export const GET_ENGAGEMENT_ANALYTICS = gql`
  query getEngagementAnalytics($range: Int, $filterType: String) {
    getEngagementAnalytics(range: $range, filterType: $filterType) {
      count
      status
      date
      attempts
    }
  }
`;

export const GET_ENGAGEMENT_LEADS_ANALYTICS = gql`
  query getEngagementLeadAnalytics($range: Int, $filterType: String) {
    getEngagementLeadAnalytics(range: $range, filterType: $filterType) {
      count
      status
      date
      source
    }
  }
`;

export const GET_LEADS_ANALYTICS = gql`
  query getLeadAnalytics($range: Int, $filterType: String) {
    getLeadAnalytics(range: $range, filterType: $filterType) {
      count
      source
    }
  }
`;

export const GET_APPOINTMENT_SOURCE = gql`
  query getAppointmentSource($range: Int) {
    getAppointmentSource(range: $range) {
      count
      source
    }
  }
`;

export const GET_APPOINTMENT_SALESPERSON = gql`
  query getAppointmentSalesperson($range: Int) {
    getAppointmentSalesperson(range: $range) {
      count
      userName
    }
  }
`;

export const GET_CRM_USERS = gql`
  {
    getCrmUsers {
      id
      userName
    }
  }
`;
export const GET_VIN_CRM_USER = gql`
  query getVinCrmUser($crmIntegrationId: Int!, $userId: Int!) {
    getVinCrmUser(crmIntegrationId: $crmIntegrationId, userId: $userId) {
      id
      userId
      vsUserId
      crmIntegrationId
    }
  }
`;
export const GET_SF_CRM_USER = gql`
  query getSfCrmUser($crmIntegrationId: Int!, $userId: Int!) {
    getSfCrmUser(crmIntegrationId: $crmIntegrationId, userId: $userId) {
      id
      userId
      sfUserId
      crmIntegrationId
    }
  }
`;

export const GET_ENGAGEMENT_MESSAGE_TEMPLATES = gql`
  query engagementMessageTemplates(
    $companyId: Int
    $userId: Int
    $isActive: Boolean
    $templateType: String!
    $search: String
  ) {
    engagementMessageTemplates(
      companyId: $companyId
      userId: $userId
      isActive: $isActive
      templateType: $templateType
      search: $search
    ) {
      id
      title
      message
      userId
      companyId
      isActive
      isCompanyShared
      location {
        id
        title
      }
      user {
        id
        fullName
      }
    }
  }
`;
export const GET_USER_APPOINTMENTS = gql`
  query getUserAppointment(
    $userIds: [Int]
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $orderDirection: String
    $startDate: Date
    $endDate: Date
    $companyId: Int
    $appointmentStatus: [String]
    $leadId: Int
  ) {
    getUserAppointment(
      userIds: $userIds
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
      startDate: $startDate
      endDate: $endDate
      companyId: $companyId
      appointmentStatus: $appointmentStatus
      leadId: $leadId
    ) {
      data {
        id
        leadOpportunityId
        companyId
        isConfirmed
        isLastActionByLead
        leadId
        userId
        startDatetime
        endDatetime
        uid
        description
        location
        locationId
        dateUpdated
        createdBy {
          id
          fullName
          dateCreated
        }
        updatedBy {
          id
          fullName
          dateCreated
        }
        sequence
        status
        summary
        leadEmail
        leadPhone
        timezone
        appointmentStatus
        isConfirmed
        discussedVoiId
        user {
          id
          fullName
          companyRoleId
          companyRole {
            id
            name
          }
        }
        lead {
          id
          fullName
          firstName
          lastName
          dateOfBirth
          leadSourceType
          leadSourceOriginalId
          companyId
          leadFileId
          crmIntegrationId
          status
          emailConsent
          emailConsentDate
          textConsent
          textConsentDate
          textConsentStatus
          otherSource
          disableConversation
          leadStatusType {
            id
            type
            status
          }
          emails {
            id
            leadId
            email
            emailType
          }
          phoneNumbers {
            id
            leadId
            phone
            phoneType
            lookupType
          }
          addresses {
            id
            leadId
            locationText
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
          }
          vehicleOfInterest {
            id
            leadId
            year
            make
            model
            trim
            description
            budget
            customerInterest
            inputType
            isCurrent
            isPrimary
            stockNumber
            vin
          }
          leadVehicleInventory {
            id
            make
            model
            year
            trim
            stockNumber
            vin
          }
          leadSource {
            id
            name
          }
        }
        leadOpportunity {
          dsOpportunityEvents {
            dsEventId
          }
          sfLeadOpportunities {
            sfOpportunityId
          }
          vsLeadOpportunities {
            id
            vsContactId
            vsLeadId
          }
          elLeadOpportunities {
            id
            elOpportunityId
            elLeadId
          }
          mfLeadOpportunities {
            id
            mfProspectId
            mfCustomerId
            mfLeadId
          }
          opportunityType {
            id
            name
            parentType {
              id
              name
            }
          }
          opportunityStatus {
            id
            name
            parentStatus {
              id
              name
            }
          }
          id
          leadId
          name
        }
      }
      count
    }
  }
`;
export const GET_APPOINTMENT_ANALYSIS = gql`
  query getAppointmentAnalysis($range: Int, $filterType: String) {
    getAppointmentAnalysis(range: $range, filterType: $filterType) {
      title
      count
      prevCount
      growthRate
    }
  }
`;
export const GET_APPOINTMENTS_BY_SOURCE = gql`
  query getAppointmentSource($range: Int, $filterType: String) {
    getAppointmentSource(range: $range, filterType: $filterType) {
      count
      source
    }
  }
`;
export const GET_APPOINTMENTS_BY_SALES_PERSON = gql`
  query getAppointmentSalesperson($range: Int, $filterType: String) {
    getAppointmentSalesperson(range: $range, filterType: $filterType) {
      count
      userName
    }
  }
`;
export const GET_COMPANY_WORK_HOURS = gql`
  query getCompanyWorkingHours($companyId: Int!, $locationId: Int!) {
    getCompanyWorkingHours(companyId: $companyId, locationId: $locationId) {
      id
      companyId
      weekDay
      isWorkingDay
      startTime
      endTime
    }
  }
`;

export const GET_LEAD_COUNT_BY_STATUS = gql`
  {
    leadsByStatus {
      count
      status
    }
  }
`;

export const GET_COMPANY_PHONE_BOTS = gql`
  query getTwilioPhoneServices($companyId: Int!, $locationId: Int) {
    getTwilioPhoneServices(companyId: $companyId, locationId: $locationId) {
      data {
        id
        companyId
        userId
        type
        serviceName
        description
        isActive
        twilioAccountSid
        twilioAuthToken
        locationId
        location {
          id
          title
        }
        user {
          id
          email
          firstName
          lastName
          phone
          locationId
        }
      }
      count
      message
      statusCode
    }
  }
`;

export const GET_LEAD_STATUS_TYPES = gql`
  {
    leadStatusTypes {
      id
      status
      type
    }
  }
`;
export const GET_ALL_VEHICLES = gql`
  {
    vehicleOfInterest {
      id
      year
      model
      make
    }
  }
`;

export const GET_VEHICLE_MAKES = gql`
  query getVehicleMakes($search: String) {
    getVehicleMakes(search: $search) {
      data {
        MakeId
        MakeName
      }
      count
    }
  }
`;
export const GET_VEHICLE_MODELS = gql`
  query getVehicleModels($makeName: String!, $year: Int!, $search: String) {
    getVehicleModels(makeName: $makeName, year: $year, search: $search) {
      data {
        ModelId
        ModelName
        VehicleTypeName
      }
      count
    }
  }
`;
export const GET_MEDIA_GALLERY_DETAILS = gql`
  query getMediaGalleryDetails($mediaGalleryCode: String!) {
    getMediaGalleryDetails(mediaGalleryCode: $mediaGalleryCode) {
      company {
        id
        profilePic
        name
        isGlobalCrm
        timezone
      }
      location {
        id
        title
      }
      mediaUrls {
        mediaUrl
        mediaType
      }
      statusCode
      message
    }
  }
`;
export const GET_APPOINTMENTS_FOR_CUSTOMER = gql`
  query getLeadAppointmentDetails($appointmentId: String!) {
    getLeadAppointmentDetails(appointmentId: $appointmentId) {
      appointment {
        id
        startDatetime
        timezone
        lead {
          id
          fullName
          firstName
          lastName
        }
        user {
          id
          firstName
          lastName
        }
      }
      prevAppointment {
        id
        startDatetime
        timezone
        discussedVoiId
      }
      workingHours {
        id
        weekDay
        isWorkingDay
        startTime
        endTime
      }
      isRescheduled
      activeAppointments {
        id
        startDatetime
        endDatetime
        status
        leadId
        appointmentStatus
        timezone
        lead {
          id
          fullName
          firstName
          lastName
        }
        user {
          id
          firstName
          lastName
        }
      }
      company {
        id
        profilePic
        name
        isGlobalCrm
        timezone
      }
      location {
        id
        title
      }
      user {
        id
        firstName
        lastName
      }
      lead {
        id
        firstName
        lastName
        fullName
        vehicleOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
      }

      statusCode
      message
    }
  }
`;
export const GET_CAMPAIGN_BY_LEAD = gql`
  query getCampaignByLead($leadId: Int!, $page: Int, $pageSize: Int) {
    getCampaignByLead(leadId: $leadId, page: $page, pageSize: $pageSize) {
      data {
        id
        name
        startDate
        endDate
        method
        textMessage
        activeInd
        isDisabled
        isPrioritize
        # campaignSelections {
        #   id
        #   type
        #   value
        #   campaignId
        # }
        # campaignTemplates {
        #   id
        #   scheduleId
        #   sourceId
        #   templateText
        #   activeInd
        #   isAfterHour
        #   afterHourTemplateText
        #   campaignSchedules {
        #     id
        #     type
        #     numericValue
        #     temporalValue
        #     sortOrder
        #     title
        #   }
        # }
        # campaignSchedules {
        #   id
        #   type
        #   numericValue
        #   temporalValue
        #   title
        #   sortOrder
        # }
        user {
          id
          fullName
          firstName
          lastName
        }
        leadMessageCount {
          campaignId
          totalSent
          totalDelivered
          totalResponded
          totalUncontacted
          responseRate
          optOutRate
          totalEngaged
          avgAttemptsBeforeResponse
          soldOpportunityCount
          scheduledAppointmentCount
          rescheduledAppointmentCount
          showedAppointmentCount
          cancelledAppointmentCount
          noShowedAppointmentCount
          ottoAppointmentSchCount
          ottoAppointmentConfCount
          totalLeads
        }
      }
      count
    }
  }
`;
export const GET_CAMPAIGN_DROPDOWN_BY_LEAD = gql`
  query getCampaignByLead($leadId: Int!, $page: Int, $pageSize: Int) {
    getCampaignByLead(leadId: $leadId, page: $page, pageSize: $pageSize) {
      data {
        id
        name
      }
    }
  }
`;
export const GET_CAMPAIGN_NUDGE_EVENT = gql`
  query getCompanyNudgeEvent($companyId: Int!) {
    getCompanyNudgeEvent(companyId: $companyId) {
      nudgeEvent {
        id
        code
        title
      }
      companyNudgeEvent {
        id
        nudgeEventId
        startDelay
        startDelayType
        frequency
        frequencyType
        firstTemplateText
        reminderTemplateText
        isSms
        isEmail
        parentTemplateText
        isWebPush
        isActive
        reminderCount
        assignedUserId
      }
    }
  }
`;
export const GET_REGIONS = gql`
  query getRegions($companyId: Int!) {
    getRegions(companyId: $companyId) {
      id
      name
      isDefault
      isActive
    }
  }
`;
export const GET_LOCATIONS = gql`
  query getLocations($companyId: Int!) {
    getLocations(companyId: $companyId) {
      id
      companyId
      regionId
      locationType
      title
      phone
      email
      addressLine1
      city
      state
      postalCode
      country
      geoLink
      website
      timezone
      isActive
      isDefault
      region {
        id
        name
      }
    }
  }
`;
export const GET_ALL_LOCATIONS = gql`
  query getAllLocations($companyId: Int!) {
    getAllLocations(companyId: $companyId) {
      id
      companyId
      regionId
      locationType
      title
      phone
      email
      addressLine1
      city
      state
      postalCode
      country
      geoLink
      website
      timezone
      isActive
      isDefault
      inventoryUrlFormat
      region {
        id
        name
      }
      openaiApiKey
      notes
    }
  }
`;
export const GET_SALESFORCE_STATUS = gql`
  {
    getSfLeadStatus {
      id
      sfLeadStatusId
      sfLeadStatus
      leadStatusTypeId
    }
  }
`;

export const GET_PAGINATED_REVIEW_MESSAGE_TEMPLATE = gql`
  query GetPaginatedReviewMessageTemplate {
    getPaginatedReviewMessageTemplate(companyId: 1, userId: 2) {
      data {
        fileName
        fileLocation
        fullFilePath
      }
      count
    }
  }
`;

export const GET_REVIEW_DATA = gql`
  query GetReviewData {
    getReviewData {
      data
    }
  }
`;

export const GET_REVIEW_INFO = gql`
  query GetReviewInfo($companyId: Int!, $locationId: Int) {
    getReviewInfo(companyId: $companyId, locationId: $locationId) {
      id
      companyId
      locationId
      facebookLink
      googleLink
      yelpLink
      dealerRaterAccessToken
      dealerRaterDealerId
      dealerRaterReviewFormLink
      isActive
    }
  }
`;

export const GET_SOCIAL_REVIEW_SETTINGS = gql`
  query getSocialReviewSettings($companyId: Int!, $locationIds: [Int], $platform: String) {
    getSocialReviewSettings(companyId: $companyId, locationIds: $locationIds, platform: $platform) {
      id
      companyId
      locationId
      platform
      platformUrl
      reviewPostUrl
      state
      accountId
      accountLocationId
      isActive
      lastFetchTs
      siteName
      isPrimary
      isOauthConnected
      businessName
      pageName
      location {
        id
        title
        city
        state
        country
        postalCode
        locationType
        isActive
      }
    }
  }
`;

export const GET_SOCIAL_REVIEW = gql`
  query GetSocialReview {
    getSocialReview {
      data
      count
    }
  }
`;

export const GET_PAGINATED_SOCIAL_REVIEW = gql`
  query GetPaginatedSocialReview(
    $companyId: Int!
    $locationIds: [Int]!
    $rating: [Int]
    $timestamp: [DateTime]
    $platform: [String]
    $status: [String]
    $timeline: DateTime
    $older: DateTime
    $userIds: [Int]
    $page: Int
    $pageSize: Int
    $search: String
    $isAiGenerated: Boolean
  ) {
    getPaginatedSocialReview(
      companyId: $companyId
      locationIds: $locationIds
      rating: $rating
      timestamp: $timestamp
      platform: $platform
      status: $status
      timeline: $timeline
      older: $older
      userIds: $userIds
      page: $page
      pageSize: $pageSize
      search: $search
      isAiGenerated: $isAiGenerated
    ) {
      data {
        id
        companyId
        locationId
        url
        authorName
        rating
        commentTs
        updateTs
        comment
        respondentName
        respondentImageUrl
        respondentCommentTs
        respondentComment
        status
        authorImageUrl
        leadId
        isAiGenerated
        socialReviewSettings {
          platform
        }
        reviewUser {
          id
          socialReviewId
          user {
            id
            fullName
            companyRole {
              id
              name
            }
          }
        }
        location {
          id
          title
        }
        lead {
          id
          fullName
          phoneNumbers {
            id
            phone
          }
          leadOpportunities {
            id
            dsOpportunityEvents {
              id
              dsEventId
            }
          }
        }
        reviewResponses {
          id
          authorName
          authorImageUrl
          comment
          commentTs
          updateTs
        }
      }
      count
    }
  }
`;

export const GET_VEHICLE_INVENTORY = gql`
  query getDsVehicleInventory($page: Int, $pageSize: Int, $search: String) {
    getDsVehicleInventory(page: $page, pageSize: $pageSize, search: $search) {
      data {
        id
        crmIntegrationId
        vehicleOfInterestId
        dsDealerId
        stockNumber
        isNew
        modelNumber
        vin
        stock
        vehicleOfInterest {
          id
          year
          make
          model
        }
      }
      count
    }
  }
`;

export const GET_CRM_INTEGRATION_TYPES = gql`
  query GetCrmIntegrationTypes {
    getCrmIntegrationTypes {
      key
      value
    }
  }
`;

export const GET_CRM_LEAD_STATUS_MAPPING = gql`
  query GetCrmLeadStatusMapping($crmIntegrationType: String) {
    getCrmLeadStatusMapping(crmIntegrationType: $crmIntegrationType) {
      data {
        mappingId
        smaiType
        smaiStatus
        crmStatus
        parentCrmStatus
        crmType
      }
    }
  }
`;

export const GET_CRM_LEAD_STATUS = gql`
  query GetCrmLeadStatus {
    getCrmLeadStatus {
      data {
        id
        statusTypeId
        type
        status
        subStatus {
          id
          statusTypeId
          type
          status
        }
      }
      crmIntegrationType
    }
  }
`;

export const GET_OPPORTUNITY_STATUS = gql`
  query getOpportunityStatus(
    $companyId: Int
    $locationIds: [Int]
    $opportunityTypeId: Int
    $fetchAll: Boolean
  ) {
    getOpportunityStatus(
      companyId: $companyId
      locationIds: $locationIds
      opportunityTypeId: $opportunityTypeId
      fetchAll: $fetchAll
    ) {
      id
      name
      opportunityType {
        id
        name
        parentType {
          id
          name
        }
      }
      parentStatus {
        id
        name
      }
      companyId
      locationId
    }
  }
`;

export const GET_CRM_INTEGRATION_TYPE = gql`
  query GetCrmLeadStatus {
    getCrmLeadStatus {
      crmIntegrationType
    }
  }
`;

export const GET_LEADS_BY_CAMPAIGN = gql`
  query getCampaignLeads($campaignId: Int, $isCheckNewLead: Boolean) {
    getCampaignLeads(campaignId: $campaignId, isCheckNewLead: $isCheckNewLead) {
      data {
        id
        fullName
        firstName
        lastName
        leadStatusTypeId
        status
        phoneNumbers {
          id
          phone
          phoneType
        }
        emails {
          id
          email
          emailType
        }
        campaignObj {
          campaigns {
            id
            name
            activeInd
          }
          activelyCampaign
        }
      }
      count
    }
  }
`;
export const GET_COMPANY_APPOINTMENT_SETTING_EVENTS = gql`
  query getCompanyAppointmentSettingEvent($companyId: Int) {
    getCompanyAppointmentSettingEvent(companyId: $companyId) {
      appointmentSettingEvent {
        id
        code
        title
        isActive
      }
      companyAppointmentSettingEvent {
        id
        appointmentSettingEventId
        companyId
        templateText
        isSms
        isEmail
        isActive
        startDelay
        startDelayType
      }
    }
  }
`;

export const GET_COMPANY_ROLES = gql`
  query getCompanyRole($companyId: Int!) {
    getCompanyRole(companyId: $companyId) {
      id
      name
      isSmaiRole
      isOpenCrmWarningPopup
      isActive
      parentRoleId
      locations {
        location {
          id
          title
        }
      }
    }
  }
`;

export const GET_COMPANY_CONSENT_SETTING_EVENTS = gql`
  query getCompanyConsentTemplateEvent($companyId: Int) {
    getCompanyConsentTemplateEvent(companyId: $companyId) {
      consentTemplateEvent {
        id
        code
        title
        isActive
      }
      companyConsentTemplateEvent {
        id
        consentTemplateEventId
        companyId
        templateText
        afterTextMode
        isActive
        onlyFirstMessage
      }
    }
  }
`;

export const GET_COMPANY_CONSENT_TEMPLATES = gql`
  query getConsentTemplateMessage {
    getConsentTemplateMessage {
      content
      statusCode
      message
    }
  }
`;

export const GET_COMPANY_AFTER_MESSAGE_CONSENT_TEMPLATE = gql`
  query getAfterMessageText {
    getAfterMessageText {
      content
      onlyFirstMessage
      statusCode
      message
      isActive
    }
  }
`;
export const GET_LEADS_BY_PHONE = gql`
  query getLeadsSearch(
    $page: Int
    $pageSize: Int
    $orderBy: String
    $orderDirection: String
    $locationId: Int
    $search: String
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
  ) {
    getLeadsSearch(
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
      orderDirection: $orderDirection
      locationId: $locationId
      search: $search
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      email: $email
    ) {
      data {
        id
        fullName
        firstName
        lastName
        companyId
        leadSourceType
        leadSourceOriginalId
        status
        leadStatusTypeId
        textConsent
        textConsentDate
        textConsentStatus
        locationId
        emails {
          id
          email
          emailType
        }
        phoneNumbers {
          id
          phone
          phoneType
          lookupType
        }
        location {
          id
          title
          locationType
        }
        leadUsers {
          userType {
            id
            useCase
            name
          }
          user {
            id
            firstName
            lastName
            companyRole {
              id
              name
            }
          }
        }
        leadOpportunities {
          id
          location {
            id
            title
          }
        }
        crmLeadObj {
          crmStatus
          parentCrmStatus
          dsExtractedLead {
            dsLeadId
          }
          activeLeadOpportunity {
            id
            opportunityStatus {
              id
              name
              parentStatus {
                id
                name
              }
            }
            opportunitySource {
              id
              name
              parentSource {
                id
                name
                parentSource {
                  id
                  name
                }
              }
            }
            vehiclesOfInterest {
              id
              leadId
              year
              make
              model
              trim
              description
              budget
              customerInterest
              inputType
              isCurrent
              isPrimary
              stockNumber
              vin
            }
            dsOpportunityEvents {
              id
              dsEntitySource
              dsMarketingChannelName
              dsEventId
            }
            sfLeadOpportunities {
              id
              sfOpportunityId
              sfOpportunityStage
            }
            dtLeadOpportunities {
              id
              dtOpportunityId
              dtOpportunityStage
            }
            vsLeadOpportunities {
              id
              vsContactId
              vsLeadId
              vsLeadStatusMapping {
                vsLeadStatusType
                vsLeadStatus
              }
            }
            elLeadOpportunities {
              id
              elOpportunityId
              elLeadId
            }
            mfLeadOpportunities {
              id
              mfProspectId
              mfCustomerId
              mfLeadId
            }
          }
        }
      }
      count
    }
  }
`;
export const GET_ENGAGEMENT_TEMPLATE_TEXT = gql`
  query getEngagementTemplateText($leadId: Int!, $content: String!) {
    getEngagementTemplateText(leadId: $leadId, content: $content) {
      content
      unknownVariables
      statusCode
      message
    }
  }
`;
export const GET_REVIEW_TEMPLATE_EVENTS = gql`
  query getCompanyReviewTemplateEvent($companyId: Int) {
    getCompanyReviewTemplateEvent(companyId: $companyId) {
      reviewTemplateEvent {
        id
        code
        title
        isActive
      }
      companyReviewTemplateEvent {
        id
        reviewTemplateEventId
        companyId
        templateText
        isActive
      }
    }
  }
`;

export const GET_LEAD_REVIEW_DETAILS = gql`
  query getLeadReviewDetail($token: String!) {
    getLeadReviewDetail(token: $token) {
      headerContent
      companyId
      locationId
      authToken
      userId
      leadId
      lead {
        id
        fullName
        leadOpportunities {
          opportunityTypeId
          opportunityStatusId
          opportunitySourceId
          createdDate
          updatedDate
          opportunityType {
            id
            name
          }
          opportunityStatus {
            id
            name
          }
          opportunitySource {
            id
            name
            parentSource {
              id
              name
              parentSource {
                id
                name
              }
            }
          }
          id
          name
          leadOpportunityUsers {
            id
            userTypeId
            userId
            isActive
            user {
              id
              fullName
              companyRole {
                id
                name
              }
            }
            userType {
              name
            }
          }
          vehiclesOfInterest {
            id
            leadId
            year
            make
            model
            trim
            description
            budget
            customerInterest
            inputType
            isCurrent
            isPrimary
            stockNumber
            vin
          }
        }
      }
      reviewLink
      company {
        profilePic
        name
      }
      location {
        title
      }
      socialReviewSettings {
        platform
        platformUrl
        reviewPostUrl
        isActive
        isPrimary
        siteName
      }
    }
  }
`;

export const GET_INITIAL_REVIEW_MESSAGE = gql`
  query getInitialReviewMessage($leadId: Int!, $leadOpportunityId: Int) {
    getInitialReviewMessage(leadId: $leadId, leadOpportunityId: $leadOpportunityId) {
      content
      statusCode
      message
    }
  }
`;
export const GET_REVIEW_LINK = gql`
  query getReviewLink($leadId: Int!, $templateText: String!) {
    getReviewLink(leadId: $leadId, templateText: $templateText) {
      content
      reviewLink
      statusCode
      message
    }
  }
`;
export const GET_SHORTEN_URL = gql`
  query getShortenUrlValue($code: String!) {
    getShortenUrlValue(code: $code) {
      value
      statusCode
      message
    }
  }
`;
export const GET_PERMISSION_ACTION_GROUP = gql`
  query getPermissionActionGroup($companyRoleId: Int, $userId: Int) {
    getPermissionActionGroup(companyRoleId: $companyRoleId, userId: $userId) {
      permissionObj {
        permissionId
        permissionTitle
        permissionCode
        permissionActions {
          permissionActionId
          actionId
          actionTitle
          actionCode
          isCheck
        }
      }
    }
  }
`;
export const GET_GENERAL_TEMPLATE_EVENTS = gql`
  query getGeneralSettings($companyId: Int) {
    getGeneralSettings(companyId: $companyId) {
      companyGeneralTemplateSettingEvents {
        generalTemplateSettingEvent {
          id
          code
          title
          isActive
        }
        companyGeneralTemplateSetting {
          id
          generalTemplateSettingEventId
          templateText
          isNudge
          isAppointment
          isConsent
          isReview
          isOtto
          isActive
          minutesAfterClose
          minutesBeforeOpen
          snoozeStart
          snoozeEnd
          isNonWorkDaySnooze
        }
      }
      crmIntegrationSetting {
        id
        crmIntegrationId
        isSendSmsAppointment
        isSendSmsSoldLead
        isSendSmsNewLead
        isSendEmailAppointment
        isSendEmailSoldLead
        isSendEmailNewLead
      }
    }
  }
`;
export const GET_USER_ACCESS_PERMISSIONS = gql`
  query getUserPermissionActions {
    getUserPermissionActions
  }
`;

export const GET_CUSTOMER_TYPES = gql`
  query getCustomerType {
    getCustomerType {
      customerType {
        id
        type
        isActive
      }
      customerSubTypes {
        id
        type
      }
    }
  }
`;

export const GET_LEAD_PHONE_LOCATION = gql`
  query getLeadPhoneLocation($phone: String!) {
    getLeadPhoneLocation(phone: $phone) {
      id
      title
      company {
        id
        name
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query getIndustries {
    getIndustries {
      id
      name
      isActive
    }
  }
`;
export const GET_OPPORTUNITTIES_TYPES = gql`
  query getOpportunityTypes($industryId: Int) {
    getOpportunityTypes(industryId: $industryId) {
      id
      name
      isActive
      parentType {
        id
        name
        isActive
      }
      industry {
        id
        name
        isActive
      }
    }
  }
`;

export const GET_OPPORTINITY_STATUS = gql`
  query getOpportunityStatus($opportunityTypeId: Int!) {
    getOpportunityStatus(opportunityTypeId: $opportunityTypeId) {
      id
      name
      isActive
      parentStatus {
        id
        name
        isActive
      }
    }
  }
`;
export const GET_OPPORTUNITY_USER_TYPE = gql`
  query getOpportunityUserTypes($locationIds: [Int]!, $opportunityTypeId: Int) {
    getOpportunityUserTypes(locationIds: $locationIds, opportunityTypeId: $opportunityTypeId) {
      id
      companyId
      locationId
      opportunityTypeId
      name
      isActive
      isRequired
    }
  }
`;

export const GET_LEAD_LIST_ACTIVITY_CENTER = gql`
  query getLeads(
    $source: [String]
    $sourceOriginal: [String]
    $combinedSource: [String]
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $orderDirection: String
    $voi: [VehicleOfInterestInputObject]
    $campaignIds: [Int]
    $status: [String]
    $leadStatusTypes: [Int]
    $crmLeadStatus: [Int]
    $isSameGroup: Boolean
    $excludeDuplicate: Boolean
    $dsEventId: String
    $dsEntityId: String
  ) {
    getLeads(
      source: $source
      sourceOriginal: $sourceOriginal
      combinedSource: $combinedSource
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
      voi: $voi
      campaignIds: $campaignIds
      status: $status
      leadStatusTypes: $leadStatusTypes
      crmLeadStatus: $crmLeadStatus
      isSameGroup: $isSameGroup
      excludeDuplicate: $excludeDuplicate
      dsEventId: $dsEventId
      dsEntityId: $dsEntityId
    ) {
      data {
        id
        fullName
        otherSource
        unreadCount
        leadCreatedDate
        leadSourceOriginalId
        textConsentStatus
        status
        location {
          id
        }
        phoneNumbers {
          id
          phone
          phoneType
          lookupType
        }
        leadVehicleInventory {
          id
          make
          model
          year
        }
        vehicleOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        activeAppointment {
          id
          startDatetime
          isConfirmed
          appointmentStatus
        }
        crmLeadObj {
          crmStatus
          parentCrmStatus
          activeLeadOpportunity {
            id
            name
            opportunityStatus {
              id
              name
              parentStatus {
                id
                name
              }
            }
            opportunitySource {
              id
              name
              parentSource {
                id
                name
                parentSource {
                  id
                  name
                }
              }
            }
            vehiclesOfInterest {
              id
              leadId
              year
              make
              model
              trim
              description
              budget
              customerInterest
              inputType
              isCurrent
              isPrimary
              stockNumber
              vin
            }
            dsOpportunityEvents {
              dsLeadId
              dsEventId
            }
            sfLeadOpportunities {
              sfLeadId
              sfOpportunityId
            }
            vsLeadOpportunities {
              vsContactId
              vsLeadId
            }
            elLeadOpportunities {
              id
              elOpportunityId
              elLeadId
            }
            mfLeadOpportunities {
              id
              mfProspectId
              mfCustomerId
              mfLeadId
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_EXTENSION_LIST_ACTIVITY_CENTER = gql`
  query getLeads(
    $source: [String]
    $sourceOriginal: [String]
    $combinedSource: [String]
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $orderDirection: String
    $voi: [VehicleOfInterestInputObject]
    $campaignIds: [Int]
    $status: [String]
    $leadStatusTypes: [Int]
    $crmLeadStatus: [Int]
    $isSameGroup: Boolean
    $excludeDuplicate: Boolean
    $dsEventId: String
    $dsEntityId: String
    $userTypesAnd: [UserTypeFilterAnd]
    $userTypesOr: [UserTypeFilterOr]
    $userIds: [Int]
    $prioritySortOrder: String
    $skipFilter: Boolean
    $leadId: Int
  ) {
    getLeads(
      source: $source
      sourceOriginal: $sourceOriginal
      combinedSource: $combinedSource
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
      voi: $voi
      campaignIds: $campaignIds
      status: $status
      leadStatusTypes: $leadStatusTypes
      crmLeadStatus: $crmLeadStatus
      isSameGroup: $isSameGroup
      excludeDuplicate: $excludeDuplicate
      dsEventId: $dsEventId
      dsEntityId: $dsEntityId
      userTypesAnd: $userTypesAnd
      userIds: $userIds
      userTypesOr: $userTypesOr
      prioritySortOrder: $prioritySortOrder
      skipFilter: $skipFilter
      leadId: $leadId
    ) {
      data {
        id
        fullName
        firstName
        lastName
        otherSource
        unreadCount
        leadCreatedDate
        leadSourceOriginalId
        textConsentStatus
        preferredLanguageCode
        status
        crmLeadObj {
          crmStatus
          parentCrmStatus
          activeLeadOpportunity {
            id
            name
            opportunityStatus {
              id
              name
              parentStatus {
                id
                name
              }
            }
            opportunitySource {
              id
              name
              parentSource {
                id
                name
                parentSource {
                  id
                  name
                }
              }
            }
            vehiclesOfInterest {
              id
              leadId
              year
              make
              model
              trim
              description
              budget
              customerInterest
              inputType
              isCurrent
              isPrimary
              stockNumber
              vin
            }
          }
        }
        activeAppointment {
          id
          appointmentStatus
          dateCreated
          dateUpdated
          endDatetime
          isConfirmed
          startDatetime
          timezone
          summary
          description
          leadOpportunityId
          userId
          leadOpportunity {
            id
            name
          }
        }
        leadOpportunities {
          id
          location {
            id
            title
          }
        }
        leadSource {
          name
          id
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        leadStatusType {
          id
          type
        }
        messageStatusType {
          status
        }
        messages {
          content
          id
          direction
          dateSent
          dateReceived
          # translatedContent
          user {
            fullName
          }
        }
        phoneNumbers {
          id
          phone
          phoneType
          lookupType
        }
        emails {
          id
          email
          emailType
        }
        location {
          id
        }
        leadCreatedDate
      }
      count
      hasNotPermitted
    }
  }
`;
export const GET_LEAD_DETAIL_ACTIVITY_CENTER = gql`
  query lead($leadId: Int!) {
    lead(leadId: $leadId) {
      id
      fullName
      companyId
      leadStatusTypeId
      consentExpireDay
      textConsentDate
      preferredLanguageCode
      textConsentStatus
      crmLeadObj {
        crmStatus
        parentCrmStatus
        activeLeadOpportunity {
          id
          opportunityStatus {
            id
            name
            parentStatus {
              id
              name
            }
          }
          opportunitySource {
            id
            name
            parentSource {
              id
              name
              parentSource {
                id
                name
              }
            }
          }
          vehiclesOfInterest {
            id
            leadId
            year
            make
            model
            trim
            description
            budget
            customerInterest
            inputType
            isCurrent
            isPrimary
            stockNumber
            vin
          }
        }
        dsExtractedLead {
          id
          dsLeadId
          dsSource
        }
        sfExtractedLead {
          id
          sfLeadId
        }
        mfExtractedLead {
          id
          mfProspectId
          mfCustomerId
        }
        dtExtractedLead {
          id
          dtLeadId
        }
      }
      leadStatusType {
        id
        type
      }

      leadSource {
        name
        id
        parentSource {
          id
          name
          parentSource {
            id
            name
          }
        }
      }
      location {
        id
        title
        locationType
        timezone
      }
      leadUsers {
        userType {
          id
          useCase
          name
        }
        user {
          id
          firstName
          lastName
          companyRole {
            id
            name
          }
        }
      }
      emails {
        id
        email
        emailType
      }
      phoneNumbers {
        id
        phone
        phoneType
        lookupType
        potentialLeads
      }
      addresses {
        id
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      leadVehicleInventory {
        id
        make
        model
        year
        trim
        stockNumber
        vin
      }
      conversationStatus {
        id
        disableConversation
        createdOn
      }
      leadOpportunities {
        id
        name
        createdDate
        updatedDate
        location {
          id
          title
        }
        leadOpportunityUsers {
          id
          userTypeId
          userId
          isActive
          user {
            id
            fullName
            companyRole {
              id
              name
            }
          }
          userType {
            name
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        opportunityTypeId
        opportunityStatusId
        opportunitySourceId
        opportunityType {
          id
          name
        }
        opportunityStatus {
          id
          name
        }
        opportunitySource {
          id
          name
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        location {
          id
          title
        }
        lead {
          id
          location {
            id
            title
          }
        }
        dtLeadOpportunities {
          id
          dtLeadSource {
            dtLeadSourceName
            leadSource {
              id
              name
            }
          }
          dtLeadStatusMapping {
            leadStatusType {
              id
              type
              status
            }
            dtLeadStatus
          }
        }
        dsOpportunityEvents {
          id
          dsEventId
          dsLeadId
          dsEntitySource
          dsMarketingChannelName
          dsTrackingCode
          leadSource {
            name
          }
        }
        sfLeadOpportunities {
          id
          sfOpportunityId
          sfOpportunityStage
        }
        vsLeadOpportunities {
          id
          vsContactId
          vsLeadId
          vsLeadStatusMapping {
            vsLeadStatusType
            vsLeadStatus
          }
        }
        elLeadOpportunities {
          id
          elOpportunityId
          elLeadId
        }
        mfLeadOpportunities {
          id
          mfProspectId
          mfCustomerId
          mfLeadId
        }
        dtLeadOpportunities {
          id
          dtOpportunityId
          dtOpportunityStage
        }
        opportunityStatus {
          id
          name
          parentStatus {
            name
            id
          }
        }
      }
    }
  }
`;
export const GET_LEAD_SOURCES_DS = gql`
  query getLeadSources($companyId: Int!, $locationIds: [Int], $search: String) {
    getLeadSources(companyId: $companyId, locationIds: $locationIds, search: $search) {
      key
      name
      companyLeadSourceId
      consentOption
      parents {
        key
        name
        consentOption
        companyLeadSourceId
        childs {
          key
          name
          companyLeadSourceId
          consentOption
        }
      }
    }
  }
`;
export const GET_LEAD_SOURCES_NEW = gql`
  query getLeadSourcesNew($companyId: Int!) {
    getLeadSourcesNew(companyId: $companyId) {
      key
      name
      childs {
        key
        name
        childs {
          key
          name
        }
      }
    }
  }
`;
export const GET_LEAD_ACTIVE_APPOINTMENT = gql`
  query lead($leadId: Int!) {
    lead(leadId: $leadId) {
      id
      activeAppointment {
        id
        userId
        locationId
        description
        summary
        discussedVoiId
        isLastActionByLead
        appointmentStatus
        isConfirmed
        leadOpportunityId
        startDatetime
        endDatetime
        timezone
        dateUpdated
        user {
          id
          fullName
          companyRoleId
          locationId
          companyRole {
            id
            name
          }
        }
        leadOpportunity {
          dsOpportunityEvents {
            id
            dsEventId
          }
          sfLeadOpportunities {
            id
            sfOpportunityId
          }
          vsLeadOpportunities {
            id
            vsContactId
            vsLeadId
          }
          elLeadOpportunities {
            id
            elOpportunityId
            elLeadId
          }
          mfLeadOpportunities {
            id
            mfProspectId
            mfCustomerId
            mfLeadId
          }
          opportunityType {
            id
            name
            parentType {
              id
              name
            }
          }
          opportunityStatus {
            id
            name
            parentStatus {
              id
              name
            }
          }
          id
          leadId
          name
        }
        createdBy {
          id
          fullName
        }
        updatedBy {
          id
          fullName
        }
        lead {
          id
          fullName
          vehicleOfInterest {
            id
            leadId
            year
            make
            model
            trim
            description
            budget
            customerInterest
            inputType
            isCurrent
            isPrimary
            stockNumber
            vin
          }
          leadVehicleInventory {
            id
            make
            model
            year
            trim
            stockNumber
            vin
          }
        }
      }
      leadOpportunities {
        id
        name
        createdDate
        updatedDate
        location {
          id
          title
        }
        leadOpportunityUsers {
          id
          userTypeId
          userId
          isActive
          user {
            id
            fullName
            companyRole {
              id
              name
            }
          }
          userType {
            name
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        opportunityTypeId
        opportunityStatusId
        opportunitySourceId
        opportunityType {
          id
          name
        }
        opportunityStatus {
          id
          name
          parentStatus {
            id
            name
          }
        }
        opportunitySource {
          id
          name
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        dsOpportunityEvents {
          id
          dsEventId
          dsEntitySource
          dsMarketingChannelName
          dsTrackingCode
        }
        sfLeadOpportunities {
          id
          sfOpportunityId
          sfOpportunityStage
        }
        vsLeadOpportunities {
          id
          vsContactId
          vsLeadId
        }
        elLeadOpportunities {
          id
          elOpportunityId
          elLeadId
        }
        mfLeadOpportunities {
          id
          mfProspectId
          mfCustomerId
          mfLeadId
        }
        opportunityStatus {
          id
          name
          parentStatus {
            name
            id
          }
        }
      }
    }
  }
`;

export const GET_DS_SOURCES = gql`
  query getDsSources {
    getDsSources {
      sourceName
      key
      marketingChannels {
        marketingChannelName
        key
        parentId
        trackingCodes {
          trackingCodeName
          parentId
        }
      }
    }
  }
`;

export const GET_ALL_USERS_ACTIVITY_CENTER = gql`
  query getUsers(
    $page: Int
    $pageSize: Int
    $search: String
    $companyId: Int!
    $isGlobalLeadOwner: Boolean
    $isLocationFilter: Boolean
    $locationIds: [Int]
    $allUser: Boolean
  ) {
    getUsers(
      page: $page
      pageSize: $pageSize
      search: $search
      companyId: $companyId
      isGlobalLeadOwner: $isGlobalLeadOwner
      isLocationFilter: $isLocationFilter
      locationIds: $locationIds
      allUser: $allUser
    ) {
      data {
        fullName
        id
        isDisabled
        companyId
        companyRole {
          id
          name
        }
      }
      count
    }
  }
`;

export const GET_ALL_USERS_APPOINTMENT_FORM = gql`
  query getUsers(
    $page: Int
    $pageSize: Int
    $search: String
    $companyId: Int!
    $isGlobalLeadOwner: Boolean
    $isLocationFilter: Boolean
    $locationIds: [Int]
    $allUser: Boolean
  ) {
    getUsers(
      page: $page
      pageSize: $pageSize
      search: $search
      companyId: $companyId
      isGlobalLeadOwner: $isGlobalLeadOwner
      isLocationFilter: $isLocationFilter
      locationIds: $locationIds
      allUser: $allUser
    ) {
      data {
        fullName
        id
        isDisabled
        companyRole {
          id
          name
        }
      }
      count
    }
  }
`;
export const EDIT_LEAD = gql`
  query lead($leadId: Int!) {
    lead(leadId: $leadId) {
      id
      firstName
      lastName
      fullName
      phoneNumbers {
        id
        phone
        phoneType
      }
      emails {
        id
        email
      }
      addresses {
        id
        addressLine1
        city
        state
        country
        postalCode
      }
      leadUsers {
        id
        userId
        user {
          firstName
          lastName
        }
      }
      leadOpportunities {
        id
        name
        createdDate
        updatedDate
        location {
          id
          title
        }
        leadOpportunityUsers {
          id
          userTypeId
          userId
          isActive
          user {
            id
            fullName
            companyRole {
              id
              name
            }
          }
          userType {
            name
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        opportunityTypeId
        opportunityStatusId
        opportunitySourceId
        opportunityType {
          id
          name
        }
        opportunityStatus {
          id
          name
          parentStatus {
            id
            name
          }
        }
        opportunitySource {
          id
          name
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        dsOpportunityEvents {
          dsEventId
        }
        sfLeadOpportunities {
          sfOpportunityId
          sfOpportunityStage
        }
        vsLeadOpportunities {
          id
          vsContactId
          vsLeadId
          vsLeadStatusMapping {
            vsLeadStatusType
            vsLeadStatus
          }
        }
        elLeadOpportunities {
          id
          elOpportunityId
          elLeadId
        }
        mfLeadOpportunities {
          id
          mfProspectId
          mfCustomerId
          mfLeadId
        }
      }
      leadStatusTypeId
      leadSourceOriginalId
      otherSource

      vehicleOfInterest {
        id
        leadId
        year
        make
        model
        trim
        description
        budget
        customerInterest
        inputType
        isCurrent
        isPrimary
        stockNumber
        vin
      }
      leadVehicleInventory {
        id
        year
        make
        model
        trim
        stockNumber
        vin
      }
      leadUsers {
        userType {
          id
          useCase
          name
        }
        user {
          id
        }
      }
    }
  }
`;

export const APPOINTMENT_LIST = gql`
  query getUserAppointment(
    $userIds: [Int]
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $orderDirection: String
    $startDate: Date
    $endDate: Date
    $companyId: Int
    $appointmentStatus: [String]
    $leadId: Int
    $createdByUserIds: [Int]
  ) {
    getUserAppointment(
      userIds: $userIds
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
      startDate: $startDate
      endDate: $endDate
      companyId: $companyId
      appointmentStatus: $appointmentStatus
      leadId: $leadId
      createdByUserIds: $createdByUserIds
    ) {
      data {
        id
        startDatetime
        timezone
        description
        leadOpportunityId
        summary
        isConfirmed
        discussedVoiId
        # content
        userId
        appointmentStatus
        lead {
          id
          fullName
          phoneNumbers {
            phone
          }
          emails {
            id
            email
          }
          leadSource {
            name
            id
            parentSource {
              id
              name
              parentSource {
                id
                name
              }
            }
          }
          vehicleOfInterest {
            id
            leadId
            year
            make
            model
            trim
            description
            budget
            customerInterest
            inputType
            isCurrent
            isPrimary
            stockNumber
            vin
          }
          leadVehicleInventory {
            id
            make
            model
            year
          }
          leadOpportunities {
            id
            name
            createdDate
            updatedDate
            location {
              id
              title
            }
            leadOpportunityUsers {
              id
              userTypeId
              userId
              isActive
              user {
                id
                fullName
                companyRole {
                  id
                  name
                }
              }
              userType {
                name
              }
            }
            vehiclesOfInterest {
              id
              leadId
              year
              make
              model
              trim
              description
              budget
              customerInterest
              inputType
              isCurrent
              isPrimary
              stockNumber
              vin
            }
            opportunityTypeId
            opportunityStatusId
            opportunitySourceId
            opportunityType {
              id
              name
            }
            opportunityStatus {
              id
              name
            }
            opportunitySource {
              id
              name
              parentSource {
                id
                name
                parentSource {
                  id
                  name
                }
              }
            }
            sfLeadOpportunities {
              id
              sfOpportunityId
              sfOpportunityStage
            }
            vsLeadOpportunities {
              id
              vsContactId
              vsLeadId
            }
            elLeadOpportunities {
              id
              elOpportunityId
              elLeadId
            }
            mfLeadOpportunities {
              id
              mfProspectId
              mfCustomerId
              mfLeadId
            }
            dsOpportunityEvents {
              id
              dsEventId
              dsEntitySource
              dsMarketingChannelName
              dsTrackingCode
            }
            dtLeadOpportunities {
              dtOpportunityId
              dtOpportunityStage
              dtLeadSource {
                leadSource {
                  name
                }
              }
            }
            opportunityStatus {
              id
              name
              parentStatus {
                name
                id
              }
            }
          }
        }
        leadOpportunityId
        leadOpportunity {
          id
          name
          leadId
          dsOpportunityEvents {
            id
            dsEventId
            dsEntitySource
            dsMarketingChannelName
            dsTrackingCode
          }
          sfLeadOpportunities {
            id
            sfOpportunityId
            sfOpportunityStage
          }
          vsLeadOpportunities {
            id
            vsContactId
            vsLeadId
          }
          elLeadOpportunities {
            id
            elOpportunityId
            elLeadId
          }
          mfLeadOpportunities {
            id
            mfProspectId
            mfCustomerId
            mfLeadId
          }
          opportunityType {
            id
            name
            parentType {
              id
              name
            }
          }
          opportunityStatus {
            id
            name
            parentStatus {
              id
              name
            }
          }
        }
        dateUpdated
        createdBy {
          id
          fullName
          dateCreated
        }
        updatedBy {
          id
          fullName
          dateCreated
        }
        user {
          id
          fullName
          companyRole {
            id
            name
          }
        }
        locationId
      }
      count
    }
  }
`;

export const LEAD_LISTING = gql`
  query getLeads(
    $source: [String]
    $sourceOriginal: [String]
    $combinedSource: [String]
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $orderDirection: String
    $voi: [VehicleOfInterestInputObject]
    $campaignIds: [Int]
    $status: [String]
    $leadStatusTypes: [Int]
    $crmLeadStatus: [Int]
    $isSameGroup: Boolean
    $stageNames: [String]
    $userTypesAnd: [UserTypeFilterAnd]
    $userTypesOr: [UserTypeFilterOr]
    $dsSources: [String]
  ) {
    getLeads(
      source: $source
      sourceOriginal: $sourceOriginal
      combinedSource: $combinedSource
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
      voi: $voi
      campaignIds: $campaignIds
      status: $status
      leadStatusTypes: $leadStatusTypes
      crmLeadStatus: $crmLeadStatus
      isSameGroup: $isSameGroup
      stageNames: $stageNames
      userTypesAnd: $userTypesAnd
      userTypesOr: $userTypesOr
      dsSources: $dsSources
    ) {
      data {
        id
        firstName
        lastName
        fullName
        phoneNumbers {
          id
          phone
          # lookupType
          phoneType
          # potentialLeads
        }
        textConsentStatus
        location {
          id
          title
        }
        leadUsers {
          userType {
            id
            useCase
            name
          }
          user {
            id
            firstName
            lastName
            companyRole {
              id
              name
            }
          }
        }
        emails {
          email
        }
        leadSource {
          name
          id
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        leadStatusType {
          id
          type
          status
        }
        crmLeadObj {
          crmStatus
          parentCrmStatus
          activeLeadOpportunity {
            id
            opportunityStatus {
              id
              name
              parentStatus {
                id
                name
              }
            }
            opportunitySource {
              id
              name
              parentSource {
                id
                name
                parentSource {
                  id
                  name
                }
              }
            }
            vehiclesOfInterest {
              id
              leadId
              year
              make
              model
              trim
              description
              budget
              customerInterest
              inputType
              isCurrent
              isPrimary
              stockNumber
              vin
            }
            dsOpportunityEvents {
              dsLeadId
              dsEventId
            }
            sfLeadOpportunities {
              sfLeadId
              sfOpportunityId
            }
            vsLeadOpportunities {
              vsContactId
              vsLeadId
            }
            elLeadOpportunities {
              id
              elOpportunityId
              elLeadId
            }
            mfLeadOpportunities {
              id
              mfProspectId
              mfCustomerId
              mfLeadId
            }
          }
          dsExtractedLead {
            dsLeadId
          }
          activeDsOpportunityEvent {
            dsMarketingChannelName
          }
        }
        vehicleOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        leadSourceType
      }
      count
    }
  }
`;
export const GET_REMAINING_LEAD_DETAILS = gql`
  query lead($leadId: Int!) {
    lead(leadId: $leadId) {
      id
      leadOpportunities {
        id
        name
        createdDate
        updatedDate
        location {
          id
          title
        }
        leadOpportunityUsers {
          id
          userTypeId
          userId
          isActive
          user {
            id
            fullName
            companyRole {
              id
              name
            }
          }
          userType {
            name
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        location {
          id
          title
        }
        opportunityTypeId
        opportunityStatusId
        opportunitySourceId
        opportunityType {
          id
          name
        }
        opportunityStatus {
          id
          name
        }
        opportunitySource {
          id
          name
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        dsOpportunityEvents {
          dsEventId
        }
        sfLeadOpportunities {
          sfOpportunityId
          sfOpportunityStage
        }
        vsLeadOpportunities {
          id
          vsContactId
          vsLeadId
          vsLeadStatusMapping {
            vsLeadStatusType
            vsLeadStatus
          }
        }
        elLeadOpportunities {
          id
          elOpportunityId
          elLeadId
        }
        mfLeadOpportunities {
          id
          mfProspectId
          mfCustomerId
          mfLeadId
        }
      }
    }
  }
`;
export const GET_COMPANY_LEAD_SOURCES = gql`
  query getCompanyLeadSources($companyId: Int!) {
    getCompanyLeadSources(companyId: $companyId) {
      id
      consentOption
      leadSourceOriginal {
        id
        name
        parentSource {
          id
          name
        }
      }
    }
  }
`;

export const GET_COMPANY_CONTACTS = gql`
  query getCompanyContacts($companyId: Int!) {
    getCompanyContacts(companyId: $companyId) {
      id
      contactEmail
      contactType
    }
  }
`;

export const GET_LEADS_PHONE_FROM_CRM = gql`
  query getLeadsByPhoneFromCrm($phone: String!) {
    getLeadsByPhoneFromCrm(phone: $phone) {
      leadId
      crmIntegrationId
      firstName
      lastName
      name
      mobile
      email
      location
      locationId
      status
      owner
      source
    }
  }
`;
export const GET_POTENTIAL_LEADS_BY_ID = gql`
  query getLeadsById($leadIds: [Int]!) {
    getLeadsById(leadIds: $leadIds) {
      id
      fullName
      firstName
      lastName
      companyId
      leadSourceType
      leadSourceOriginalId
      status
      leadStatusTypeId
      leadStatusType {
        id
        type
        status
      }
      emails {
        id
        email
        emailType
      }
      phoneNumbers {
        id
        phone
        phoneType
        lookupType
      }
      vehicleOfInterest {
        id
        leadId
        year
        make
        model
        trim
        description
        budget
        customerInterest
        inputType
        isCurrent
        isPrimary
        stockNumber
        vin
      }
      leadVehicleInventory {
        id
        make
        model
        year
        trim
        stockNumber
        vin
      }
      leadSource {
        name
        id
        parentSource {
          id
          name
          parentSource {
            id
            name
          }
        }
      }
      location {
        id
        title
        locationType
      }
      leadUsers {
        userType {
          id
          useCase
          name
        }
        user {
          id
          firstName
          lastName
          companyRole {
            id
            name
          }
        }
      }
      crmLeadObj {
        crmStatus
        parentCrmStatus
        sfExtractedLead {
          id
          sfLeadId
        }
        mfExtractedLead {
          id
          mfProspectId
          mfCustomerId
        }
      }
      leadOpportunities {
        id
        name
        createdDate
        updatedDate
        location {
          id
          title
        }
        leadOpportunityUsers {
          id
          userTypeId
          userId
          isActive
          user {
            id
            fullName
            companyRole {
              id
              name
            }
          }
          userType {
            name
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        opportunityTypeId
        opportunityStatusId
        opportunitySourceId
        opportunityType {
          id
          name
        }
        opportunityStatus {
          id
          name
        }
        opportunitySource {
          id
          name
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        lead {
          id
          location {
            id
            title
          }
        }
        sfLeadOpportunities {
          sfOpportunityId
          sfOpportunityStage
        }
        vsLeadOpportunities {
          id
          vsContactId
          vsLeadId
          vsLeadStatusMapping {
            vsLeadStatusType
            vsLeadStatus
          }
        }
        elLeadOpportunities {
          id
          elOpportunityId
          elLeadId
        }
        mfLeadOpportunities {
          id
          mfProspectId
          mfCustomerId
          mfLeadId
        }
      }
    }
  }
`;
export const GET_LEAD_BY_PHONE = gql`
  query leadByPhone($phone: String!) {
    leadByPhone(phone: $phone) {
      id
      fullName
      firstName
      lastName
      dateOfBirth
      leadSourceType
      leadSourceOriginalId
      companyId
      leadFileId
      crmIntegrationId
      status
      emailConsent
      emailConsentDate
      textConsent
      textConsentDate
      textConsentStatus
      otherSource
      disableConversation
      leadStatusTypeId
      locationId
      emails {
        id
        leadId
        email
        emailType
      }
      phoneNumbers {
        id
        leadId
        phone
        phoneType
        lookupType
      }
      leadUsers {
        userType {
          id
          useCase
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
      location {
        id
        title
      }
    }
  }
`;
export const GET_COMPANY_CONSENT = gql`
  query getCompanyConsentTemplateEvent($companyId: Int) {
    getCompanyConsentTemplateEvent(companyId: $companyId) {
      consentTemplateEvent {
        code
        isActive
      }
      companyConsentTemplateEvent {
        templateText
        afterTextMode
        onlyFirstMessage
        isActive
      }
    }
  }
`;

export const GET_CONSENT_TEMPLATE = gql`
  query getEngagementTemplateText($leadId: Int!, $content: String!) {
    getEngagementTemplateText(leadId: $leadId, content: $content) {
      unknownVariables
      content
      statusCode
      message
    }
  }
`;
export const GET_CRM_OWNER_DROPDOWN = gql`
  query getUserTypes($companyId: Int!) {
    getUserTypes(companyId: $companyId) {
      id
      name
      useCase
      opportunityType {
        id
        name
      }
    }
  }
`;

export const GET_CRM_OWNER_USER = gql`
  query getUserTypes($companyId: Int!) {
    getUserTypes(companyId: $companyId) {
      id
      name
      useCase
      opportunityType {
        id
        name
      }
      users {
        id
        fullName
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

export const GET_ALL_USERS_TEAMS = gql`
  query getUsers(
    $page: Int
    $pageSize: Int
    $search: String
    $companyId: Int!
    $isGlobalLeadOwner: Boolean
    $isLocationFilter: Boolean
    $locationIds: [Int]
    $allUser: Boolean
    $companyRoles: [Int]
  ) {
    getUsers(
      page: $page
      pageSize: $pageSize
      search: $search
      companyId: $companyId
      isGlobalLeadOwner: $isGlobalLeadOwner
      isLocationFilter: $isLocationFilter
      locationIds: $locationIds
      allUser: $allUser
      companyRoles: $companyRoles
    ) {
      data {
        id
        fullName
        location {
          id
          title
        }
        companyRole {
          id
          name
          locations {
            locationId
          }
        }
      }
      count
    }
  }
`;

export const GET_LEAD_OPPORTUNITY = gql`
  query lead($leadId: Int!) {
    lead(leadId: $leadId) {
      id
      crmLeadObj {
        crmStatus
        parentCrmStatus
        dsExtractedLead {
          id
          dsLeadId
          dsSource
        }
        sfExtractedLead {
          id
          sfLeadId
        }
        mfExtractedLead {
          id
          mfProspectId
          mfCustomerId
        }
        dtExtractedLead {
          id
          dtLeadId
        }
      }
      leadOpportunities {
        id
        name
        createdDate
        updatedDate
        location {
          id
          title
        }
        leadOpportunityUsers {
          id
          userTypeId
          userId
          isActive
          user {
            id
            fullName
            companyRole {
              id
              name
            }
          }
          userType {
            name
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        opportunityTypeId
        opportunityStatusId
        opportunitySourceId
        opportunityType {
          id
          name
        }
        opportunityStatus {
          id
          name
        }
        opportunitySource {
          id
          name
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        lead {
          id
          fullName
          location {
            id
            title
          }
        }
        opportunityTypeId
        opportunityStatusId
        description
        createdDate
        isActive
        opportunityType {
          id
          name
        }
        dsOpportunityEvents {
          dsEventId
          dsEntitySource
          dsMarketingChannelName
          dsTrackingCode
          leadSource {
            name
          }
        }
        sfLeadOpportunities {
          sfOpportunityId
          sfOpportunityStage
        }
        vsLeadOpportunities {
          id
          vsContactId
          vsLeadId
          vsLeadStatusMapping {
            vsLeadStatusType
            vsLeadStatus
          }
        }
        elLeadOpportunities {
          id
          elOpportunityId
          elLeadId
        }
        mfLeadOpportunities {
          id
          mfProspectId
          mfCustomerId
          mfLeadId
        }
        dtLeadOpportunities {
          dtOpportunityId
          dtOpportunityStage
          dtLeadSource {
            leadSource {
              name
            }
          }
        }
        leadOpportunityUsers {
          isActive
          user {
            firstName
            lastName
            companyRole {
              id
              name
            }
          }
          userType {
            name
          }
        }
        opportunityStatus {
          id
          name
          parentStatus {
            name
            id
          }
        }
      }
    }
  }
`;
export const GET_CRM_LEAD_STATUS_TYPE = gql`
  query GetCrmLeadStatus {
    getCrmLeadStatus {
      crmIntegrationType
    }
  }
`;

export const GET_LEAD_INFO_APPOINTMENT = gql`
  query lead($leadId: Int!) {
    lead(leadId: $leadId) {
      id
      fullName
      location {
        id
      }
      crmLeadObj {
        crmIntegrationType
        crmStatus
        parentCrmStatus
        customerType {
          id
          type
        }
        customerSubType {
          id
          type
        }
        dsExtractedLead {
          id
          dsLeadId
          dsSource
        }
        activeDsOpportunityEvent {
          id
          dsEventId
          dsMarketingChannelName
        }
        sfExtractedLead {
          id
          sfLeadId
        }
        mfExtractedLead {
          id
          mfProspectId
          mfCustomerId
        }
        activeSfOpportunityEvent {
          id
          sfOpportunityId
        }
      }
      leadOpportunities {
        id
        name
        createdDate
        updatedDate
        location {
          id
          title
        }
        leadOpportunityUsers {
          id
          userTypeId
          userId
          isActive
          user {
            id
            fullName
            companyRole {
              id
              name
            }
          }
          userType {
            name
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        opportunityTypeId
        opportunityStatusId
        opportunitySourceId
        opportunityType {
          id
          name
        }
        opportunityStatus {
          id
          name
        }
        opportunitySource {
          id
          name
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        lead {
          id
          location {
            id
            title
          }
        }
        sfLeadOpportunities {
          id
          sfOpportunityId
          sfOpportunityStage
        }
        vsLeadOpportunities {
          id
          vsContactId
          vsLeadId
        }
        elLeadOpportunities {
          id
          elOpportunityId
          elLeadId
        }
        mfLeadOpportunities {
          id
          mfProspectId
          mfCustomerId
          mfLeadId
        }
        dsOpportunityEvents {
          id
          dsEventId
          dsEntitySource
          dsMarketingChannelName
          dsTrackingCode
        }
      }
    }
  }
`;

export const GET_PROSPECT_FILTERS = gql`
  {
    getAutoAttributes {
      id
      key
      label
      relatedKeys
      filterType
      isCustomValues
      isView
      isActive
      autoAttributeValues {
        id
        value
        mappingKey
      }
    }
  }
`;
export const GET_LEAD_OPPORTUNITIES_BY_ID = gql`
  query getLeadOpportunities($leadId: Int!) {
    getLeadOpportunities(leadId: $leadId) {
      leadOpportunities {
        id
        name
        createdDate
        updatedDate
        location {
          id
          title
        }
        leadOpportunityUsers {
          id
          userTypeId
          userId
          isActive
          user {
            id
            fullName
            companyRole {
              id
              name
            }
          }
          userType {
            name
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        opportunityTypeId
        opportunityStatusId
        opportunitySourceId
        opportunityType {
          id
          name
        }
        opportunityStatus {
          id
          name
        }
        opportunitySource {
          id
          name
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        lead {
          id
          location {
            id
            title
          }
        }
        dsOpportunityEvents {
          id
          dsEventId
          dsEntitySource
          dsMarketingChannelName
          dsTrackingCode
        }
        sfLeadOpportunities {
          id
          sfOpportunityId
          sfOpportunityStage
        }
        vsLeadOpportunities {
          id
          vsContactId
          vsLeadId
        }
        elLeadOpportunities {
          id
          elOpportunityId
          elLeadId
        }
        mfLeadOpportunities {
          id
          mfProspectId
          mfCustomerId
          mfLeadId
        }
        opportunityStatus {
          id
          name
          parentStatus {
            name
            id
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        # users {
        #   id
        #   user {
        #     id
        #     firstName
        #     lastName
        #     companyRole {
        #       id
        #       name
        #     }
        #   }
        # }
      }
    }
  }
`;
export const GET_LEAD_DETAIL_CLICK = gql`
  query lead($leadId: Int!) {
    lead(leadId: $leadId) {
      id
      fullName
      firstName
      lastName
      otherSource
      unreadCount
      leadCreatedDate
      status
      companyId
      leadSourceOriginalId
      leadStatusTypeId
      textConsentStatus
      consentExpireDay
      textConsentDate
      activeAppointment {
        id
        startDatetime
        isConfirmed
        appointmentStatus
      }

      messages {
        id
        dateReceived
        dateSent
        content
      }
      crmLeadObj {
        crmStatus
        parentCrmStatus
        activeLeadOpportunity {
          id
          opportunityStatus {
            id
            name
            parentStatus {
              id
              name
            }
          }
          opportunitySource {
            id
            name
            parentSource {
              id
              name
              parentSource {
                id
                name
              }
            }
          }
          vehiclesOfInterest {
            id
            leadId
            year
            make
            model
            trim
            description
            budget
            customerInterest
            inputType
            isCurrent
            isPrimary
            stockNumber
            vin
          }
          dsOpportunityEvents {
            dsLeadId
            dsEventId
          }
          sfLeadOpportunities {
            sfLeadId
            sfOpportunityId
          }
          vsLeadOpportunities {
            vsContactId
            vsLeadId
          }
          elLeadOpportunities {
            id
            elOpportunityId
            elLeadId
          }
          mfLeadOpportunities {
            id
            mfProspectId
            mfCustomerId
            mfLeadId
          }
        }
        dsExtractedLead {
          dsLeadId
        }
        activeDsOpportunityEvent {
          dsMarketingChannelName
        }
      }
      leadStatusType {
        id
        type
      }
      leadSource {
        name
        id
        parentSource {
          id
          name
          parentSource {
            id
            name
          }
        }
      }
      location {
        id
        title
        locationType
      }
      leadUsers {
        userType {
          id
          useCase
          name
        }
        user {
          id
          firstName
          lastName
          companyRole {
            id
            name
          }
        }
      }
      vehicleOfInterest {
        id
        leadId
        year
        make
        model
        trim
        description
        budget
        customerInterest
        inputType
        isCurrent
        isPrimary
        stockNumber
        vin
      }
      emails {
        id
        email
        emailType
      }
      phoneNumbers {
        id
        phone
        phoneType
        lookupType
        potentialLeads
      }
      addresses {
        id
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      leadVehicleInventory {
        id
        make
        model
        year
        trim
        stockNumber
        vin
      }
      conversationStatus {
        id
        disableConversation
        createdOn
      }
      leadOpportunities {
        id
        name
        createdDate
        updatedDate
        location {
          id
          title
        }
        leadOpportunityUsers {
          id
          userTypeId
          userId
          isActive
          user {
            id
            fullName
            companyRole {
              id
              name
            }
          }
          userType {
            name
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        opportunityTypeId
        opportunityStatusId
        opportunitySourceId
        opportunityType {
          id
          name
        }
        opportunityStatus {
          id
          name
        }
        opportunitySource {
          id
          name
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
        lead {
          id
          location {
            id
            title
          }
        }
        dsOpportunityEvents {
          id
          dsEventId
          dsEntitySource
          dsMarketingChannelName
          dsTrackingCode
        }
        sfLeadOpportunities {
          id
          sfOpportunityId
          sfOpportunityStage
        }
        vsLeadOpportunities {
          id
          vsContactId
          vsLeadId
        }
        elLeadOpportunities {
          id
          elOpportunityId
          elLeadId
        }
        mfLeadOpportunities {
          id
          mfProspectId
          mfCustomerId
          mfLeadId
        }
        opportunityStatus {
          id
          name
          parentStatus {
            name
            id
          }
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        # users {
        #   id
        #   user {
        #     id
        #     firstName
        #     lastName
        #     companyRole {
        #       id
        #       name
        #     }
        #   }
        # }
      }
    }
  }
`;

export const GET_NOTIFICATION_CONFIG = gql`
  query GetNotificationConfig {
    me {
      id
      notificationConfig {
        id
        notificationsAllowed
        dndStart
        dndEnd
        timezone
        email
        app
        sms
        responses
        interval
        details {
          id
          setType
          filterSetId
          count
        }
      }
    }
  }
`;

export const GET_WEB_CHAT_WIDGET_CONNECTION = gql`
  query getWebChatConnection($leadId: Int) {
    getWebChatConnection(leadId: $leadId) {
      id
      connected
      sid
    }
  }
`;

export const GET_LEAD_CONTECTED_BY_SOURCE = gql`
  query getEngagementLeadAnalytics($range: Int, $filterType: String) {
    getEngagementLeadAnalytics(range: $range, filterType: $filterType) {
      count
      status
      date
      source
    }
  }
`;

export const GET_ONLY_ENGAGEMENT_LEADS_ANALYTICS = gql`
  query getCompleteLeadAnalysis($filterType: String) {
    getCompleteLeadAnalysis(filterType: $filterType) {
      engagementLeadAnalysis {
        count
        source
        status
        date
      }
      leadAnalysis {
        count
        source
      }
    }
  }
`;

export const ME = gql`
  query ME {
    me {
      id
      email
      firstName
      lastName
      fullName
      phone
      isDisabled
      locationId
      location {
        id
        title
      }
      locations {
        id
        isOpenCrmWarningPopup
        location {
          id
          title
          timezone
        }
      }
      companyRoleId
      preferredLanguageCode
      companyRole {
        id
        name
        parentRoleId
        isOpenCrmWarningPopup
      }
      company {
        id
        name
        isDisabled
        aingineSourceId
        timezone
        locationLink
        isOptinConsentMethod
        isOpenCrmWarningPopup
        isGlobalCrm
        userRoles {
          id
          companyId
          userId
          role {
            id
            name
            canCreateUsers
            canCreateTeams
            canViewProspects
            isCompanyAdmin
            canViewAutoAnalytics
            canViewAdExport
            canViewClm
            canViewGle
            canViewEngagements
          }
        }
        crmIntegration {
          id
          integrationType
          active
          locationId
          location {
            id
            title
            locationType
          }
        }
      }
      userAccounts {
        id
        isDisabled
        company {
          id
          name
          isDisabled
          aingineSourceId
          isOptinConsentMethod
          isGlobalCrm
        }
      }
      profilePic
      role {
        id
        name
        canCreateUsers
        canCreateTeams
        canViewProspects
        isCompanyAdmin
        canViewAutoAnalytics
        canViewAdExport
        canViewClm
        canViewGle
        canViewEngagements
      }
      teamsLeader {
        id
      }
    }
  }
`;
export const GET_GENERIC_FILTER_TYPES = gql`
  query getGenericFilterTypes {
    getGenericFilterTypes {
      id
      code
      name
    }
  }
`;
export const GET_USER_LEAD_FILTER = gql`
  query getUserLeadFilters($filterName: String, $genericFilterTypeId: Int) {
    getUserLeadFilters(filterName: $filterName, genericFilterTypeId: $genericFilterTypeId) {
      id
      locationId
      userId
      filterName
      genericFilterJson {
        id
        filterJson
        isCurrent
        userLeadFilter {
          id
        }
      }
    }
  }
`;

export const GET_REVIEW_TRACKING_DETAILS = gql`
  query getReviewTrackingDetails(
    $companyId: Int!
    $locationIds: [Int]!
    $leadId: Int
    $range: Int
    $filterType: String
    $leadName: String
    $userName: String
    $startDate: DateTime
    $endDate: DateTime
    $status: [String]
    $item: [String]
    $page: Int
    $pageSize: Int
  ) {
    getReviewTrackingDetails(
      companyId: $companyId
      locationIds: $locationIds
      leadId: $leadId
      range: $range
      filterType: $filterType
      leadName: $leadName
      userName: $userName
      startDate: $startDate
      endDate: $endDate
      status: $status
      item: $item
      page: $page
      pageSize: $pageSize
    ) {
      data {
        id
        companyId
        locationId
        leadId
        userId
        messageId
        shortenUrlId
        actionType
        actionValue
        createTs
        updateTs
        location {
          socialReviewSettings {
            platform
          }
        }
        socialReview {
          comment
        }
        message {
          channel {
            id
            name
          }
          content
        }
        lead {
          fullName
          phoneNumbers {
            id
            leadId
            phone
            phoneType
            lookupType
          }
        }
        user {
          fullName
        }
      }
      count
    }
  }
`;
export const GET_REVIEW_TRACKING_SUMMARY = gql`
  query getReviewTrackingSummary(
    $companyId: Int!
    $locationIds: [Int]!
    $year: Int
    $month: Int
    $week: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getReviewTrackingSummary(
      companyId: $companyId
      locationIds: $locationIds
      year: $year
      month: $month
      week: $week
      startDate: $startDate
      endDate: $endDate
    ) {
      reviewStats {
        title
        name
        month
        frequency
        date
        total
      }
    }
  }
`;

export const GET_APPOINTMENT_DETAILS_WITH_TOKEN = gql`
  query getAppointmentDetailsWithToken($appointmentToken: String!) {
    getAppointmentDetailsWithToken(appointmentToken: $appointmentToken) {
      appointment {
        uid
        startDatetime
        appointmentStatus
        timezone
        lead {
          fullName
        }
        user {
          firstName
          lastName
        }
      }
      company {
        profilePic
        name
      }
      location {
        title
      }
      user {
        firstName
        lastName
      }
      lead {
        fullName
      }
      statusCode
      message
      operation
      operationData
    }
  }
`;

export const GET_CAMPAIGN_LIST_FILTERS = gql`
  query getCampaigns(
    $page: Int
    $pageSize: Int
    $search: String
    $orderBy: String
    $orderDirection: String
    $activeInd: String
  ) {
    getCampaigns(
      page: $page
      pageSize: $pageSize
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
      activeInd: $activeInd
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_SCHEDULED_MESSAGES_FOR_LEAD = gql`
  query getScheduledMessagesForLead($leadId: Int!) {
    getScheduledMessagesForLead(leadId: $leadId) {
      id
      leadId
      user {
        firstName
        lastName
      }
      isTaskExecuted
      taskSendDatetime
      message
    }
  }
`;

export const GET_LOCATION_VEHICLE_INVENTORY = gql`
  query getVehicleInventory($locationId: Int!, $search: String, $page: Int, $pageSize: Int) {
    getVehicleInventory(
      locationId: $locationId
      search: $search
      page: $page
      pageSize: $pageSize
    ) {
      count
      data {
        id
        locationId
        make
        model
        year
        trim
        stockNumber
        modelNumber
        bodyStyle
        vin
        msrp
        sellingPrice
        currencyUnit
        isNew
        exteriorColor
        interiorColor
        vehicleInventoryMedias {
          mediaUrl
        }
        location {
          id
          inventoryUrlFormat
        }
      }
    }
  }
`;

export const GET_REVIEW_TRACKING_BY_PLATFORM = gql`
  query getReviewTrackingByPlatform(
    $companyId: Int!
    $locationIds: [Int]!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getReviewTrackingByPlatform(
      companyId: $companyId
      locationIds: $locationIds
      startDate: $startDate
      endDate: $endDate
    ) {
      platform
      count
    }
  }
`;

export const GET_REVIEW_TRACKING_BY_USER = gql`
  query getReviewTrackingByUser(
    $companyId: Int!
    $locationIds: [Int]!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getReviewTrackingByUser(
      companyId: $companyId
      locationIds: $locationIds
      startDate: $startDate
      endDate: $endDate
    ) {
      user {
        id
        fullName
      }
      count
    }
  }
`;

export const GET_RECENT_REVIEW_TRACKING_DETAIL = gql`
  query getRecentReviewTrackingDetail(
    $companyId: Int!
    $locationIds: [Int]!
    $pageSize: Int!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getRecentReviewTrackingDetail(
      companyId: $companyId
      locationIds: $locationIds
      pageSize: $pageSize
      startDate: $startDate
      endDate: $endDate
    ) {
      ottoGeneratedCount
      selfWrittenCount
      recentReviewDetails {
        id
        authorName
        updateTs
        commentTs
        socialReviewSettings {
          id
          platform
        }
      }
    }
  }
`;

export const GET_LEAD_OPPORTUNITIES = gql`
  query getLeadOpportunity(
    $opportunitySourceId: [Int]
    $opportunityStatusId: [Int]
    $opportunityTypeId: [Int]
    $page: Int
    $pageSize: Int
    $search: String
    $searchVehicleObjects: [VehicleOfInterestInputObject]
    $userTypesAnd: [UserTypeFilterAnd]
    $userTypesOr: [UserTypeFilterOr]
    $orderBy: String
    $orderDirection: String
  ) {
    getLeadOpportunity(
      opportunitySourceId: $opportunitySourceId
      opportunityStatusId: $opportunityStatusId
      opportunityTypeId: $opportunityTypeId
      page: $page
      pageSize: $pageSize
      search: $search
      searchVehicleObjects: $searchVehicleObjects
      userTypesAnd: $userTypesAnd
      userTypesOr: $userTypesOr
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        id
        name
        isActive
        opportunityTypeId
        opportunityStatusId
        opportunitySourceId
        createdDate
        updatedDate
        lead {
          id
          fullName
          location {
            id
            title
          }
          crmLeadObj {
            dsExtractedLead {
              id
              dsLeadId
            }
            activeDsOpportunityEvent {
              id
              dsEventId
              dsMarketingChannelName
            }
            sfExtractedLead {
              id
              sfLeadId
            }
            mfExtractedLead {
              id
              mfProspectId
              mfCustomerId
            }
            vsExtractedLead {
              id
              vsContactId
            }
            activeSfOpportunityEvent {
              id
              sfOpportunityId
            }
          }
        }
        location {
          id
          title
        }
        vehiclesOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        leadOpportunityUsers {
          id
          userTypeId
          userId
          isActive
          user {
            id
            fullName
            firstName
            lastName
            companyRole {
              id
              name
            }
          }
          userType {
            id
            name
          }
        }
        dsOpportunityEvents {
          dsEventId
          dsLeadId
          dsEntitySource
          dsMarketingChannelName
          dsTrackingCode
        }
        sfLeadOpportunities {
          sfOpportunityId
          sfOpportunityStage
        }
        vsLeadOpportunities {
          id
          vsContactId
          vsLeadId
          vsLeadStatusMapping {
            vsLeadStatusType
            vsLeadStatus
          }
        }
        elLeadOpportunities {
          id
          elOpportunityId
          elLeadId
        }
        mfLeadOpportunities {
          id
          mfProspectId
          mfCustomerId
          mfLeadId
        }
        opportunityType {
          id
          name
          parentType {
            id
            name
          }
        }
        opportunityStatus {
          id
          name
          parentStatus {
            id
            name
          }
        }
        opportunitySource {
          id
          name
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_OPPORTUNITY_SOURCES = gql`
  query getOpportunitySources($companyId: Int!, $locationIds: [Int]!) {
    getOpportunitySources(companyId: $companyId, locationIds: $locationIds) {
      id
      name
      parentId
      isDefault
      parentSource {
        id
        name
        isDefault
      }
    }
  }
`;

export const GET_OPPORTUNITY_VOI = gql`
  query getOpportunityVois($companyId: Int!, $locationIds: [Int]!) {
    getOpportunityVois(companyId: $companyId, locationIds: $locationIds) {
      id
      make
      model
      year
      text
    }
  }
`;

export const GET_APPLICATION_USE_CASES = gql`
  query getApplicationUseCases {
    getApplicationUseCases {
      id
      title
      code
    }
  }
`;
export const GET_APPLICATION_LEVELS = gql`
  query getApplicationLevels {
    getApplicationLevels {
      id
      title
      code
    }
  }
`;
export const GET_AI_SUGGESTION_QUICK_TIP = gql`
  query getAiSuggestionQuickTip($companyId: Int, $locationIds: [Int], $applicationUseCaseId: Int) {
    getAiSuggestionQuickTip(
      companyId: $companyId
      locationIds: $locationIds
      applicationUseCaseId: $applicationUseCaseId
    ) {
      id
      companyId
      locationId
      tip
    }
  }
`;
export const GET_COMPANY_ROLES_NAMES = gql`
  query getCompanyRole($companyId: Int!) {
    getCompanyRole(companyId: $companyId) {
      id
      name
    }
  }
`;

export const GET_LEVELS = gql`
  query getApplicationLevels {
    getApplicationLevels {
      id
      title
      code
      isActive
    }
  }
`;

export const GET_PROMPTS = gql`
  query getPrompts($companyId: Int!, $search: String) {
    getPrompts(companyId: $companyId, search: $search) {
      id
      userId
      teamId
      companyRoleId
      locationId
      companyId
      applicationLevelId
      applicationUseCaseId
      promptText
      promptSources {
        leadSourceOriginalId
        leadSourceOriginal {
          id
          name
        }
      }
      company {
        id
        name
      }
      user {
        id
        fullName
      }
      team {
        id
        name
      }
      companyRole {
        id
        name
      }
      location {
        id
        title
      }
      applicationLevel {
        id
        title
        code
      }
      applicationUseCase {
        id
        title
        code
      }
    }
  }
`;

export const GET_TEAM_NAMES = gql`
  query getTeams($page: Int, $pageSize: Int, $search: String, $companyId: Int!) {
    getTeams(page: $page, pageSize: $pageSize, search: $search, companyId: $companyId) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_USE_CASES = gql`
  query getApplicationUseCases {
    getApplicationUseCases {
      id
      title
      code
      isActive
    }
  }
`;

export const GET_AI_RESPONSE = gql`
  query getAiResponse(
    $companyId: Int!
    $applicationUseCaseId: Int!
    $leadId: Int
    $socialReviewId: Int
    $postId: Int
    $regenerate: Boolean
    $generateTasks: Boolean
    $campaignInput: CampaignInput
    $aiSuggestionUserInput: AiSuggestionUserInput
  ) {
    getAiResponse(
      companyId: $companyId
      applicationUseCaseId: $applicationUseCaseId
      leadId: $leadId
      socialReviewId: $socialReviewId
      postId: $postId
      regenerate: $regenerate
      generateTasks: $generateTasks
      campaignInput: $campaignInput
      aiSuggestionUserInput: $aiSuggestionUserInput
    ) {
      content
      statusCode
      statusMessage
      futureTasks
    }
  }
`;

export const GET_AI_RESPONSE_FOR_USER_REVIEW = gql`
  query getAiResponse(
    $companyId: Int!
    $applicationUseCaseId: Int!
    $leadId: Int
    $socialReviewId: Int
    $postId: Int
    $token: String!
    $locationId: Int!
    $userId: Int!
    $regenerate: Boolean
    $generateTasks: Boolean
  ) {
    getAiResponse(
      companyId: $companyId
      applicationUseCaseId: $applicationUseCaseId
      leadId: $leadId
      socialReviewId: $socialReviewId
      postId: $postId
      token: $token
      locationId: $locationId
      userId: $userId
      regenerate: $regenerate
      generateTasks: $generateTasks
    ) {
      content
      statusCode
      statusMessage
      futureTasks
    }
  }
`;

export const GET_SOCIAL_REVIEW_BY_USER = gql`
  query getSocialReviewByUser(
    $companyId: Int!
    $locationIds: [Int]!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getSocialReviewByUser(
      companyId: $companyId
      locationIds: $locationIds
      startDate: $startDate
      endDate: $endDate
    ) {
      user {
        id
        fullName
      }
      count
    }
  }
`;

export const GET_SOCIAL_REVIEW_BY_PLATFORM = gql`
  query getSocialReviewByPlatform(
    $companyId: Int!
    $locationIds: [Int]!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getSocialReviewByPlatform(
      companyId: $companyId
      locationIds: $locationIds
      startDate: $startDate
      endDate: $endDate
    ) {
      platform
      count
    }
  }
`;

export const GET_GENERIC_FILTERS = gql`
  query getGenericFilters($filterName: String, $genericFilterTypeId: Int) {
    getGenericFilters(filterName: $filterName, genericFilterTypeId: $genericFilterTypeId) {
      id
      filterName
      genericFilterTypeId
      userId
      genericFilterJson {
        id
        filterJson
      }
    }
  }
`;

// add calender queries
export const GET_USER_WORKING_HOURS = gql`
  query getUserWorkingHours($locationId: Int!) {
    getUserWorkingHours(locationId: $locationId) {
      id
      userId
      weekDay
      isWorkingDay
      startTime
      endTime
      createdOn
      isActive
      isDeleted
    }
  }
`;

export const GET_USER_SCHEDULE = gql`
  query getUserSchedule($locationId: Int!) {
    getUserSchedule(locationId: $locationId) {
      id
      name
      fromDatetime
      toDatetime
      isAvailable
      isDelete
      updatedOn
      isCompany
      isLocation
      isFullDay
    }
  }
`;

export const GET_LOCATION_SCHEDULE = gql`
  query getLocationSchedule($locationId: Int) {
    getLocationSchedule(locationId: $locationId) {
      id
      name
      fromDatetime
      toDatetime
      isAvailable
      isDelete
      updatedOn
      isCompany
      isLocation
    }
  }
`;

export const GET_COMPANY_SCHEDULE = gql`
  query getCompanySchedule($companyId: Int) {
    getCompanySchedule(companyId: $companyId) {
      id
      name
      companyId
      fromDatetime
      toDatetime
      isAvailable
      createdOn
      updatedOn
      isDelete
    }
  }
`;
// end add calender queries

export const GET_SUPPORTED_LANGUAGES = gql`
  query getSupportedLanguages {
    getSupportedLanguages {
      id
      languageName
      languageCode
      companyId
    }
  }
`;

export const GET_DETECTED_LANGUAGE = gql`
  query getLanguageCode($content: String!) {
    getLanguageCode(content: $content) {
      code
    }
  }
`;

export const GET_TRANSLATION_SETTINGS = gql`
  query getTranslationSettings($leadId: Int!) {
    getTranslationSettings(leadId: $leadId) {
      id
      customerLanguage
      userLanguage
      isAutomaticTranslation
      isIncludeOriginalMessage
      isTranslationPreview
    }
  }
`;

export const GET_OPPORTUNITY_STATUS_NEW = gql`
  query getOpportunityStatusNew($locationIds: [Int]) {
    getOpportunityStatusNew(locationIds: $locationIds) {
      key
      name
      childs {
        key
        name
        childs {
          key
          name
        }
      }
    }
  }
`;
export const GET_LEAD_NOTES = gql`
  query getLeadNotes($leadId: Int!) {
    getLeadNotes(leadId: $leadId) {
      id
      note
      createTs
      updateTs
      createdBy {
        id
        fullName
      }
      updatedBy {
        id
        fullName
      }
      leadOpportunity {
        id
        name
      }
    }
  }
`;
export const GET_COMPANY_DOCUMENTS = gql`
  query getCompanyDocuments(
    $companyId: Int
    $locationId: Int
    $page: Int
    $pageSize: Int
    $search: String
  ) {
    getCompanyDocuments(
      companyId: $companyId
      locationId: $locationId
      page: $page
      pageSize: $pageSize
      search: $search
    ) {
      count
      statusCode
      statusMessage
      companyDocuments {
        id
        name
        templateUrl
        description
        isActive
        isMasked
        createTs
        updateTs
        extraData
      }
    }
  }
`;
export const GET_LEAD_OPPORTUNITY_DOCUMENT_URL = gql`
  query getLeadOpportunityDocumentUrl(
    $companyDocumentId: Int!
    $leadId: Int
    $leadOpportunityId: Int
  ) {
    getLeadOpportunityDocumentUrl(
      companyDocumentId: $companyDocumentId
      leadId: $leadId
      leadOpportunityId: $leadOpportunityId
    ) {
      statusCode
      statusMessage
      documentUrl
    }
  }
`;
